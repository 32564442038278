import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import html2canvas from 'html2canvas';

import './scss/user-results.scss';

import logo from '../../assets/images/popup-logo.png';
import background_img from '../../assets/images/user-results.png';
import fern_left from '../../assets/images/fern-left.png';
import fern_right from '../../assets/images/fern-right.png';

import { useTranslation } from "react-i18next";

const UserResults = (props) => {
    const { config, language, uuid, results, close } = props;
    const [data, setData] = useState({
        name: '',
        update_time: ''
    });
    const [qr, setQr] = useState('');
    const [popupHeight, setPopupHeight] = useState(1262);
    const { t } = useTranslation();

    const headerRef = useRef(null);
    const contentRef = useRef(null);
    const footerRef = useRef(null);

    const getImage = () => {
        let page = document.getElementById("user-results-wrapper");

        window.scrollTo(0, 0);
        html2canvas(page, {
            scale: 3,
            useCORS: true,
            scrollY: -window.scrollY,
            height: page.scrollHeight
        }).then((canvas) => {
            canvas.toBlob(function(blob) {
                const URL = window.URL || window.webkitURL;
                const output = new Blob([blob], { type: 'image/jpeg' });
                const fileURL = URL.createObjectURL(output);

                if(navigator.userAgent.match('CriOS')) {
                    window.open(fileURL);
                } else {
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.target = '_blank';
                    link.download = 'mytradescore.jpg';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            });
        });
    }

    useEffect(() => {
        const getQrCode = async() => {
            const url = await QRCode.toDataURL(window.location.origin.replace(/\/$/, '') + '?utm_source=Wechat&utm_medium=SocialMedia&utm_campaign=TradingContest&utm_term=InviteFriends&utm_content=' + uuid, {color: {dark: '#000000', light: '#FFFFFF'}, margin: 1, quality: 1});
            setQr(url);
        };

        getQrCode();
        getData();

        const contentHeight = headerRef.current.clientHeight + contentRef.current.clientHeight + footerRef.current.clientHeight;
        setPopupHeight(contentHeight + 150);
    }, []);

    const getData = () => {
        if (Object.keys(results.results.first).length > 0) {
            setData({
                name: results.results.first.name,
                update_time: results.results.first.update_time
            });
        } else if (Object.keys(results.results.second).length > 0) {
            setData({
                name: results.results.second.name,
                update_time: results.results.second.update_time
            });
        }
    };

    return (
        <div className={`modal-container popupResult ${language}`}>
            <div className="modal_wrapper">
                <div className="modal" style={{height: popupHeight + 'px'}}>
                    <button className="close" onClick={close}>
                        X
                    </button>
                    <div id="user-results-wrapper">
                        <img id="background" src={background_img} alt="QR Background" style={{height: popupHeight + 'px'}}/>
                        <div className="content-wrapper">
                            <header className="modal-header" ref={headerRef}>
                                <img src={logo} alt="Logo"/>
                                <p className="border">{t('myResult.charityCup')}</p>
                                <h5 className="title">{t('myResult.globalTradingCom')}</h5>
                            </header>
                            <div className="modal-content" ref={contentRef}>
                                <p className="border">{t('myResult.unlockAmazingPrizes')}</p>
                                <div className="bonus" dangerouslySetInnerHTML={{__html: t('myResult.cashPrizes')}}>
                                </div>
                                <div className="details">
                                    <div className="whitebg">
                                        <div className="member">
                                            <div className="image">
                                                {
                                                    results.profile &&
                                                    <img src={results.profile} alt="Profile"/>
                                                }
                                            </div>
                                            <div className="name">{data.name && data.name.length > 3 ? data.name.slice(0, -3) + Array(4).join("X") : data.name}</div>
                                        </div>
                                        <div className="stage_scroll">
                                            <div className="stage_wrapper">
                                            {
                                                Object.keys(results.results.first).length > 0 &&
                                                <div className="stage">
                                                    <p>{results.account === config.rankingCredential.classicId ? t('ranking.classic') + " " + t('ranking.round_1') : t('ranking.master') + " " + t('ranking.round_1')}</p>
                                                    {/*<p>{results.account === "9807-50112211e" ? t('ranking.classic') + " " + t('ranking.round_1') : t('ranking.master') + " " + t('ranking.round_1')}</p>*/}
                                                    <div className="place">
                                                        <img src={fern_left} alt="Fern"/>
                                                        {t('myResult.ranking') + " " + results.results.first.ranking + " " + t('myResult.placing')}
                                                        <img src={fern_right} alt="Fern"/>
                                                    </div>
                                                    <div className="yield">{t('myResult.percOfYield')} <span>{Number(results.results.first.rate_of_return * 100).toFixed(2) + "%"}</span></div>
                                                </div>
                                            }
                                            {
                                                Object.keys(results.results.second).length > 0 &&
                                                <div className="stage">
                                                    <p>{results.account === config.rankingCredential.classicId ? t('ranking.classic') + " " + t('ranking.round_2') : t('ranking.master') + " " + t('ranking.round_2')}</p>
                                                    {/*<p>{results.account === "9807-50112211e" ? t('ranking.classic') + " " + t('ranking.round_2') : t('ranking.master') + " " + t('ranking.round_2')}</p>*/}
                                                    <div className="place">
                                                        <img src={fern_left} alt="Fern"/>
                                                        {t('myResult.ranking') + " " + results.results.second.ranking + t('myResult.placing')}
                                                        <img src={fern_right} alt="Fern"/>
                                                    </div>
                                                    <div className="yield">{t('myResult.rateOfReturn')} <span>{Number(results.results.second.rate_of_return * 100).toFixed(2) + "%"}</span></div>
                                                </div>
                                            }
                                            {
                                                Object.keys(results.results.final).length > 0 &&
                                                <div className="stage last mb8">
                                                    <p>{results.account === config.rankingCredential.classicId ? t('ranking.classic') + " " + t('myResult.finalStagePercOfYield') : t('ranking.master') + " " + t('myResult.finalStagePercOfYield')}</p>
                                                    {/*<p>{results.account === "9807-50112211e" ? t('ranking.classic') + " " + t('myResult.finalStagePercOfYield') : t('ranking.master') + " " + t('myResult.finalStagePercOfYield')}</p>*/}
                                                    <div className="place percentage">
                                                        <img src={fern_left} alt="Fern"/>
                                                        {Number(results.results.final.rate_of_return * 100).toFixed(2) + "%"}
                                                        <img src={fern_right} alt="Fern"/>
                                                    </div>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                        <div className="date">{data.update_time + " (UTC+0)"}</div>
                                    </div>
                                </div>
                                <div className="qrcode">
                                    <div className="wrapper">
                                        <img src={qr} alt="QR Code"/>
                                    </div>
                                    <div className="scan">{t('myResult.scanToLearnMore')}<br/>{t('myResult.toFollowTheLatestUpdate')}</div>
                                </div>
                            </div>
                            <div className="modal-footer" ref={footerRef} dangerouslySetInnerHTML={{__html: t('myResult.highRiskDisclosure')}}>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="download-page">
                    <button onClick={getImage}>
                        {t('download')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UserResults;
