import React, { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import { WOW } from "wowjs";

import 'react-tabs/style/react-tabs.css';
import './scss/prize.scss';
import scroll from "react-scroll";

const responsive = {
    tablet: {
        breakpoint: { max: 1170, min: 821 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 1024, min: 0 },
        items: 1
    }
};

const Prize = (props) => {
    const { t } = useTranslation();
    const { triggerTermsExpand, language } = props;

    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();
    }, []);

    const triggerTermsTab = () => {
        scroll.scroller.scrollTo('prize-rules', {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100
        });
        triggerTermsExpand();
    }

    return (
        <div id="prize" className={language}>
            <div className="section-title wow fadeInUp">
                {t('prize.title')}
            </div>
            <div className="updated wow fadeInUp" dangerouslySetInnerHTML={{__html: t('prize.desc')}}></div>
            <div>
                <Tabs>
                    <TabList>
                        <Tab>{t('prize.tab-1')}</Tab>
                        <Tab>{t('prize.tab-2')}</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="top-panel desktop">
                            <div className="card winner wow fadeInUp" data-wow-delay="0.5s">
                                <div className="text">
                                    <span>{t('prize.champion.title')}</span>
                                    <span className="gold">{t('prize.champion.classic.prize')}</span>
                                    <span>{t('prize.champion.classic.disclaimer')}</span>
                                </div>
                            </div>
                            <div className="card wow fadeInUp" data-wow-delay="1s">
                                <div className="text">
                                    <span>{t('prize.runner-up-1.title')}</span>
                                    <span className="silver">{t('prize.runner-up-1.classic.prize')}</span>
                                    <span>{t('prize.runner-up-1.classic.disclaimer')}</span>
                                </div>
                            </div>
                            <div className="card wow fadeInUp" data-wow-delay="1.5s">
                                <div className="text">
                                    <span>{t('prize.runner-up-2.title')}</span>
                                    <span className="bronze">{t('prize.runner-up-2.classic.prize')}</span>
                                    <span>{t('prize.runner-up-2.classic.disclaimer')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="top-panel mobile">
                            <Carousel
                                responsive={responsive}
                                showDots={true}
                                arrows={false}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="prize-carousel-container"
                                dotListClass="prize-custom-dot-list"
                                itemClass="carousel-item-padding-40-px"
                            >
                                <div className="card winner wow fadeInUp">
                                    <div className="text">
                                        <span>{t('prize.champion.title')}</span>
                                        <span className="gold">{t('prize.champion.classic.prize')}</span>
                                        <span>{t('prize.champion.classic.disclaimer')}</span>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp">
                                    <div className="text">
                                        <span>{t('prize.runner-up-1.title')}</span>
                                        <span className="silver">{t('prize.runner-up-1.classic.prize')}</span>
                                        <span>{t('prize.runner-up-1.classic.disclaimer')}</span>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp">
                                    <div className="text">
                                        <span>{t('prize.runner-up-2.title')}</span>
                                        <span className="bronze">{t('prize.runner-up-2.classic.prize')}</span>
                                        <span>{t('prize.runner-up-2.classic.disclaimer')}</span>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                        <div className="bottom-panel">
                            <div className="card wow fadeInUp" data-wow-delay="0.5s">
                                <div className="card-wrapper">
                                    <div className="title">
                                        <div className="icon"></div>
                                        <div>{t('prize.round-1.title')}</div>
                                    </div>
                                    <div className="content">
                                        <div className="badge">
                                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/ranking-first.png"/>
                                        </div>
                                        <div className="text">
                                            <div>
                                                <span>{t('prize.round-1.classic.prize')}</span>
                                                <span>{t('prize.round-1.classic.disclaimer')}</span>
                                            </div>
                                            <div>{t('prize.round-1.desc')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card wow fadeInUp" data-wow-delay="1s">
                                <div className="card-wrapper">
                                    <div className="title">
                                        <div className="icon"></div>
                                        <div>{t('prize.round-2.title')}</div>
                                    </div>
                                    <div className="content">
                                        <div className="badge">
                                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/ranking-first.png"/>
                                        </div>
                                        <div className="text">
                                            <div>
                                                <span>{t('prize.round-2.classic.prize')}</span>
                                                <span>{t('prize.round-2.classic.disclaimer')}</span>
                                            </div>
                                            <div>{t('prize.round-2.desc')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="disclaimer wow fadeInUp">
                            <span>{t('prize.disclaimer-1')}</span>
                            <span>{t('prize.disclaimer-2')} <span onClick={() => triggerTermsTab()} dangerouslySetInnerHTML={{__html: t('prize.awardsSetting')}}></span></span>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="top-panel desktop">
                            <div className="card winner">
                                <div className="text">
                                    <span>{t('prize.champion.title')}</span>
                                    <span className="gold">{t('prize.champion.master.prize')}</span>
                                    <span>{t('prize.champion.master.disclaimer')}</span>
                                </div>
                            </div>
                            <div className="card">
                                <div className="text">
                                    <span>{t('prize.runner-up-1.title')}</span>
                                    <span className="silver">{t('prize.runner-up-1.master.prize')}</span>
                                    <span>{t('prize.runner-up-1.master.disclaimer')}</span>
                                </div>
                            </div>
                            <div className="card">
                                <div className="text">
                                    <span>{t('prize.runner-up-2.title')}</span>
                                    <span className="bronze">{t('prize.runner-up-2.master.prize')}</span>
                                    <span>{t('prize.runner-up-2.master.disclaimer')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="top-panel mobile">
                            <Carousel
                                responsive={responsive}
                                showDots={true}
                                arrows={false}
                                infinite={true}
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="prize-carousel-container"
                                dotListClass="prize-custom-dot-list"
                                itemClass="carousel-item-padding-40-px"
                            >
                                <div className="card winner">
                                    <div className="text">
                                        <span>{t('prize.champion.title')}</span>
                                        <span className="gold">{t('prize.champion.master.prize')}</span>
                                        <span>{t('prize.champion.master.disclaimer')}</span>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="text">
                                        <span>{t('prize.runner-up-1.title')}</span>
                                        <span className="silver">{t('prize.runner-up-1.master.prize')}</span>
                                        <span>{t('prize.runner-up-1.master.disclaimer')}</span>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="text">
                                        <span>{t('prize.runner-up-2.title')}</span>
                                        <span className="bronze">{t('prize.runner-up-2.master.prize')}</span>
                                        <span>{t('prize.runner-up-2.master.disclaimer')}</span>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                        <div className="bottom-panel">
                            <div className="card">
                                <div className="card-wrapper">
                                    <div className="title">
                                        <div className="icon"></div>
                                        <div>{t('prize.round-1.title')}</div>
                                    </div>
                                    <div className="content">
                                        <div className="badge">
                                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/ranking-first.png"/>
                                        </div>
                                        <div className="text">
                                            <div>
                                                <span>{t('prize.round-1.master.prize')}</span>
                                                <span>{t('prize.round-1.master.disclaimer')}</span>
                                            </div>
                                            <div>{t('prize.round-1.desc')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-wrapper">
                                    <div className="title">
                                        <div className="icon"></div>
                                        <div>{t('prize.round-2.title')}</div>
                                    </div>
                                    <div className="content">
                                        <div className="badge">
                                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/ranking-first.png"/>
                                        </div>
                                        <div className="text">
                                            <div>
                                                <span>{t('prize.round-2.master.prize')}</span>
                                                <span>{t('prize.round-2.master.disclaimer')}</span>
                                            </div>
                                            <div>{t('prize.round-2.desc')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="disclaimer">
                            <span>{t('prize.disclaimer-1')}</span>
                            <span>{t('prize.disclaimer-2')} <span href="#terms" onClick={() => triggerTermsTab()}  dangerouslySetInnerHTML={{__html: t('prize.awardsSetting')}}></span> {t('prize.dot')}</span>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

 export default Prize;
