import React, { useState, useEffect } from 'react';
import Collapsible from "react-collapsible";
import { useTranslation } from "react-i18next";
import { WOW } from "wowjs";

import Dropdown from '../common/dropdown';

import './scss/user-results.scss';

const customDropdown = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' : 'grey',
        padding: 20,
    }),
    control: () => ({
        width: 150
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
};

const UserResults = (props) => {
    const { config, userResults, competitionProgress, triggerPopup } = props;
    const [mtAccount, setMtAccount] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [firstRoundData, setFirstRoundData] = useState([]);
    const [secondRoundData, setSecondRoundData] = useState([]);
    const [finalRoundData, setFinalRoundData] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        getOverallResults(getSelectedAccount ? getSelectedAccount.value : '');
    }, [userResults]);

    useEffect(() => {
        getAccountType();
    }, [firstRoundData, secondRoundData]);

    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();
    }, []);

    const getStageResults = (round) => {
        return userResults.filter(result => { return result.competition_rounds === round; });
    };

    const getProfileImage = () => {
        let img = [];

        if (getStageResults(1).length > 0) {
            img = getStageResults(1)[0].uhead ? JSON.parse(getStageResults(1)[0].uhead).oss : '';
        } else if (getStageResults(2).length > 0) {
            img = getStageResults(2)[0].uhead ? JSON.parse(getStageResults(2)[0].uhead).oss : '';
        }

        return img;
    };

    const getMtAccountList = () => {
        let mt_account = [];

        if (getStageResults(1).length > 0) {
            mt_account = getStageResults(1).map((elm, index) => ({value: elm.login, label: "MT4 " + elm.login}));
        } else if (getStageResults(2).length > 0) {
            mt_account = getStageResults(2).map((elm, index) => ({value: elm.login, label: "MT4 " + elm.login}));
        }

        return mt_account;
    };

    const getSelectedAccount = mtAccount ? getMtAccountList().find(obj => obj.value === mtAccount) : (getMtAccountList() ? getMtAccountList()[0] : '');

    const getOverallResults = (account) => {
        setFirstRoundData(displayData(account, 1) ? displayData(account, 1) : []);
        setSecondRoundData(displayData(account, 2) ? displayData(account, 2) : []);
        setFinalRoundData(displayData(account, 3) ? displayData(account, 3) : []);
    };

    const getAccountType = () => {
        if (Object.keys(firstRoundData).length > 0) {
            setAccountType(firstRoundData.account_type);
        } else if (Object.keys(secondRoundData).length > 0) {
            setAccountType(secondRoundData.account_type);
        }
    };

    const displayData = (account, round) => {
        return getStageResults(round).find(obj => obj.login === account);
    };

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    const changeSelected = (selected) => {
        setMtAccount(selected.value);
        getOverallResults(selected.value);
    };

    const popupContent = () => {
        return {
            profile: getProfileImage(),
            account: accountType,
            results: {
                first: firstRoundData,
                second: secondRoundData,
                final: finalRoundData
            }
        }
    };

    return (
        <div id="user-results">
            <div className="section-title wow fadeInUp">
                {t('results.myRecord')}
            </div>
            <div className="profile">
                <div className="left">
                    <div className="profile_image wow fadeInUp">
                        {
                            getProfileImage() &&
                                <img src={getProfileImage()} alt="Profile"/>
                        }
                    </div>
                    <div className="details wow fadeInUp">
                        <Dropdown className="account-dropdown" options={getMtAccountList()} styles={customDropdown} placeholder="" changeSelected={changeSelected} value={getSelectedAccount} />
                        <div className="group">{t('results.group')}：<span>{accountType === config.rankingCredential.classicId ? t('ranking.classic') : t('ranking.master')}</span></div>
                    </div>
                </div>
            </div>
            {
                Object.keys(firstRoundData).length > 0 &&
                    <Collapsible trigger={t('results.firstStage')} >
                        <div className="ranking wow fadeInUp">{t('results.ranking')} <span>{firstRoundData.ranking}</span></div>
                        <div className="summary wow fadeInUp">
                            <div className="wrapper">
                                <div className={competitionProgress === 1 ? "status in-progress" : "status complete"}>{competitionProgress === 1 ? t('results.inProgress') : t('results.completed')}</div>
                                <div className="yield"><font>{t('results.yield')}</font> <span>{Number(firstRoundData.rate_of_return * 100).toFixed(2) + "%"}</span></div>
                                <div className="maxDrawdown"><font>{t('results.maxDrawdown')}</font> <span>{Number(firstRoundData.maxdown * 100).toFixed(2) + "%"}</span></div>
                                <div className="currentNetWorth"><font>{t('results.currentNetWorth')}</font> <span>{"USD " + Number(firstRoundData.equity).toFixed(2)}</span></div>
                                <div className="totalTradingLots"><font>{t('results.totalTradingLots')}</font> <span>{Number(firstRoundData.volume).toFixed(2)}</span></div>
                            </div>
                        </div>
                    </Collapsible>
            }
            {
                Object.keys(secondRoundData).length > 0 &&
                    <Collapsible trigger={t('results.secondStage')} >
                        <div className="ranking wow fadeInUp">{t('results.ranking')} <span>{secondRoundData.ranking}</span></div>
                        <div className="summary wow fadeInUp">
                            <div className="wrapper">
                                <div className={competitionProgress === 3 ? "status in-progress" : "status complete"}>{competitionProgress === 3 ? t('results.inProgress') : t('results.completed')}</div>
                                <div className="yield"><font>{t('results.yield')}</font> <span>{Number(secondRoundData.rate_of_return * 100).toFixed(2) + "%"}</span></div>
                                <div className="maxDrawdown"><font>{t('results.maxDrawdown')}</font> <span>{Number(firstRoundData.maxdown * 100).toFixed(2) + "%"}</span></div>
                                <div className="currentNetWorth"><font>{t('results.currentNetWorth')}</font> <span>{"USD " + Number(secondRoundData.equity).toFixed(2)}</span></div>
                                <div className="totalTradingLots"><font>{t('results.totalTradingLots')}</font> <span>{Number(secondRoundData.volume).toFixed(2)}</span></div>
                            </div>
                        </div>
                    </Collapsible>
            }
            {
                Object.keys(finalRoundData).length > 0 &&
                    <Collapsible trigger={t('results.finals')} >
                        <div className="ranking wow fadeInUp">{t('results.ranking')} <span>{finalRoundData.ranking}</span></div>
                        <div className="summary wow fadeInUp">
                            <div className="wrapper">
                                <div className={competitionProgress === 5 ? "status in-progress" : "status complete"}>{competitionProgress === 5 ? t('results.inProgress') : t('results.completed')}</div>
                                <div className="yield"><font>{t('results.yield')}</font> <span>{Number(finalRoundData.rate_of_return * 100).toFixed(2) + "%"}</span></div>
                                <div className="maxDrawdown"><font>{t('results.maxDrawdown')}</font> <span>{Number(finalRoundData.rate_of_return * 100).toFixed(2) + "%"}</span></div>
                                <div className="currentNetWorth"><font>{t('results.currentNetWorth')}</font> <span>{"USD " + Number(finalRoundData.equity).toFixed(2)}</span></div>
                                <div className="totalTradingLots"><font>{t('results.totalTradingLots')}</font> <span>{Number(finalRoundData.volume).toFixed(2)}</span></div>
                            </div>
                        </div>
                    </Collapsible>
            }
            <div className="btn">
                <div className="btnShare wow fadeInUp" onClick={() => openPopup('user-results', popupContent())}>{t('results.share')}</div>
                <div className="btnTrade wow fadeInUp" onClick={() => window.location.href = config.crmUrl}>{t('results.trade')}</div>
            </div>
        </div>
    )
}

export default UserResults;
