import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Navbar from './navbar';
import Content from './content';
import Footer from './footer';
import PopUp from '../popup';

import './scss/index.scss';
import postscribe from "postscribe";

const Layout = () => {
    const [language, setLanguage] = useState('en');
    const [config, setConfig] = useState({});
    const [popup, setPopup] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [popupContent, setPopupContent] = useState(null);
    const [disableScroll, setDisableScroll] = useState(false);
    const [giftAmount, setGiftAmount] = useState(0);
    const [giftEligibility, setGiftEligibility] = useState(null);
    const [isParticipant, setIsParticipant] = useState(false);
    const [friendsInvited, setFriendsInvited] = useState(0);
    const [userResults, setUserResults] = useState([]);
    const [pointsMallLink, setPointsMallLink] = useState(null);
    const [popupCountdownText, setPopupCountdownText] = useState('');
    const [popupCountdownTimer, setPopupCountdownTimer] = useState('');

    const { t, i18n } = useTranslation();

    const changeLanguage = (lang) => {
        const url_lang = /^.{8}[^]*\/([^]*)/.exec(window.location.href)[1];
        i18n.changeLanguage(lang);
        if(['en', 'sc', 'tc', 'kr', 'vn', 'th'].includes(url_lang) && (url_lang !== lang)) {
            window.location.href = `${window.location.origin}/${lang}`;
        }

        document.title = t('site.title');
        document.getElementsByTagName('meta')["description"].content = t('site.desc');
    };

    const getParamString = (name, url) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    const fireLivechat = (lang) => {
        let group = "";

        switch (lang) {
            case "sc":
            case "tc":
                group = "1";
                break;
            case "kr":
                group = "2";
                break;
            case "en":
            case "th":
                group = "3";
                break;
            case "vn":
                group = "4";
                break;
        }

        postscribe('#layout', '<script type=\'text/javascript\'>\n' +
            'window.__lc = window.__lc || {};\n' +
            'window.__lc.license = 10956587\n' +
            'window.__lc.group = ' + group + ';\n' +
            '(function() {\n' +
            'var lc = document.createElement(\'script\'); lc.type = \'text/javascript\'; lc.async = true;\n' +
            'lc.src = (\'https:\' == document.location.protocol ? \'https://\' : \'http://\')+\'cdn.livechatinc.com/tracking.js\';\n' +
            'var s = document.getElementsByTagName(\'script\')[0]; s.parentNode.insertBefore(lc, s);\n' +
            '})();\n' +
            '</script>');
    }

    useEffect(() => {
        const url_lang = /^.{8}[^]*\/([^]*)/.exec(window.location.href)[1];

        const getLanguage = async() => {
            const lang = await axios({
                url: '/api/v1/location/',
                method: 'GET'
            });

            if (lang.data.code === 0) {
                changeLanguage(lang.data.data.lang);
                fireLivechat(lang.data.data.lang);
            }
        };

        if (['en', 'sc', 'tc', 'kr', 'vn', 'th'].includes(url_lang)) {
            changeLanguage(url_lang);
            fireLivechat(url_lang);
        } else {
            getLanguage();
        }
    }, []);

    useEffect(() => {
        const getConfig = async() => {
            const config = await axios({
                url: '/api/v1/config/',
                method: 'GET'
            });

            if (config.data.config) {
                setConfig(config.data.config);
            }
        };

        const handleLogin = async() => {
            const login = await axios({
                url: '/api/v1/user/redirectSignIn',
                method: 'GET',
                withCredentials: true,
                headers: {
                    "Access-Control-Allow-Origin": config.apiUrl
                },
                params: { token }
            });
            //console.log("login", login);

            if (login.data.code === 0) {
                setWithExpiry('uuid', login.data.data.uuid, 1800000);
                setWithExpiry('isChina', login.data.data.country, 1800000);

                switch (login.data.data.country) {
                    case "1": 
                        i18n.changeLanguage('sc'); 
                        break;
                        case "81" :
                        case "82" :
                        case "71" :
                        i18n.changeLanguage('tc');
                        break;
                    case "KOR":
                        i18n.changeLanguage('kr');
                        break;
                    case "THA":
                        i18n.changeLanguage('th');
                        break;
                    case "VNM":
                        i18n.changeLanguage('vn');
                        break;
                    default:
                        i18n.changeLanguage('en'); 
                }

            }

            getData();
        };

        const getData = async() => {
            if (getWithExpiry('uuid')) {
                getEligibility();

                const gifts = await axios({
                    url: '/api/v1/activity/gifts/quantity',
                    method: 'GET',
                    withCredentials: true,
                    headers: {
                        "Access-Control-Allow-Origin": config.apiUrl
                    }
                });

                if (gifts.data.code === 0) {
                    // gifts.data.data.todayIsReceive: true = no gifts available
                    setGiftAmount(gifts.data.data.giftAmount);
                }

                const friends = await axios({
                    url: '/api/v1/activity/friend',
                    method: 'GET',
                    withCredentials: true,
                    headers: {
                        "Access-Control-Allow-Origin": config.apiUrl
                    }
                });

                if (friends.data.code === 0) {
                    setFriendsInvited(friends.data.data);
                }

                const results = await axios({
                    url: '/api/v1/activity/competition/accountInfo',
                    method: 'GET',
                    withCredentials: true,
                    headers: {
                        "Access-Control-Allow-Origin": config.apiUrl
                    }
                });

                if (results.data.code === 0) {
                    setUserResults(results.data.data);
                }

                const points_mall = await axios({
                    url: '/api/v1/user/goToMall',
                    method: 'GET',
                    withCredentials: true,
                    headers: {
                        "Access-Control-Allow-Origin": config.apiUrl
                    }
                });

                if (points_mall.data.code === 0) {
                    setPointsMallLink(points_mall.data.data.ip);
                }
            }
        };

        const token = getParamString('token', window.location.href);

        if (Object.keys(config).length) {
            if (token && !checkLogin()) {
                localStorage.clear();
                handleLogin();
            } else {
                getData();
            }
        } else {
            getConfig();
        }
    }, [config]);

    i18n.on('languageChanged', function(lng) {
        setLanguage(lng);
    });

    const getEligibility = async() => {
        const eligibility = await axios({
            url: '/api/v1/activity/gifts/eligibility',
            method: 'GET',
            withCredentials: true,
            headers: {
                "Access-Control-Allow-Origin": config.apiUrl
            }
        });
        setGiftEligibility(eligibility.data.code);
        setIsParticipant(eligibility.data.code !== 3001);
    };

    const checkLogin = () => {
        return !!getWithExpiry('uuid');
    };

    const setWithExpiry = (key, value, ttl) => {
        const now = new Date();

        const item = {
            value: value,
            expiry: now.getTime() + ttl
        }
        localStorage.setItem(key, JSON.stringify(item));
    };

    const getWithExpiry = (key) => {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    }

    const togglePopup = () => {
        setPopup(!popup);
    };

    const triggerPopup = (type, content = null) => {
        if (content) {
            setPopupContent(content);
        }
        setPopup(true);
        setPopupType(type);
    };

    const updateTimer = (text, timer) => {
        setPopupCountdownText(text);
        setPopupCountdownTimer(timer);
    };

    const navbarOpen = (scroll) => {
        setDisableScroll(scroll);
    };

    const updateEligibility = () => {
        getEligibility();
    };

    return (
        <div className={disableScroll ? "layout hamburger-open" : "layout hamburger-close"} id="layout">
            <Navbar config={config}
                    changeLanguage={changeLanguage.bind(this)}
                    defaultLanguage={language}
                    triggerPopup={triggerPopup.bind(this)}
                    navbarOpen={navbarOpen.bind(this)}
                    uuid={getWithExpiry('uuid')}
                    isParticipant={isParticipant}/>
            <Content language={language}
                     config={config}
                     triggerPopup={triggerPopup.bind(this)}
                     updateTimer={updateTimer.bind(this)}
                     uuid={getWithExpiry('uuid')}
                     isParticipant={isParticipant}
                     isChina={getWithExpiry('isChina') === "1"}
                     isKorea={getWithExpiry('isChina') === "KOR"}
                     pointsMallLink={pointsMallLink}
                     userResults={userResults}
                     friendsInvited={friendsInvited}
                     giftAmount={giftAmount}
                     giftEligibility={giftEligibility}/>
            {
                popup &&
                <PopUp language={language}
                       config={config}
                       uuid={getWithExpiry('uuid')}
                       close={togglePopup.bind(this)}
                       triggerPopup={triggerPopup.bind(this)}
                       countdownText={popupCountdownText}
                       countdownTimer={popupCountdownTimer}
                       type={popupType}
                       content={popupContent}
                       getEligibility={updateEligibility.bind(this)}/>
            }
            <Footer language={language}/>
        </div>
    );
}

export default withRouter(Layout);
