import React, {useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { WOW } from "wowjs";

import './scss/activity.scss';

const tooltip_gift = `
    <div style="padding: 16px;">
        <span style="
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;">
            兑换礼品活动规则</span>
        <p style="
            margin-top: 8px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.83;
            letter-spacing: 1.5px;">
            1) 是次大赛的 #DooTrader 限量礼品是 Doo Prime 定制的 SKG K3 便携式智能颈椎按摩仪；<br/>
            2) 此活动在2021年10月11日至12月8日期间举行，以大赛时间 UTC+0 为准；<br/>
            3) 以上活动只适用于中国大陆客户，并仅支持国内寄送；<br/>
            4) 参赛者只需成功开设是次大赛专用MT4账户，并完成以下条件，即有机会换领限量礼品一份：<br/>
            - 开设最少一个大赛专用MT4账户<br/>
            - 总入金达到 $2,000 或以上<br/>
            - 总交易手数达到10手或以上 (以平仓订单计算) <br/>
            5) 成功换领及礼品寄出后，参赛者会收到电邮通知，请注意查收；<br/> 
            6) 成功换领后，礼品在会在14个工作日内寄出，送达时间视乎地区而定；<br/>
            7) 请正确填写寄送信息，如因填写信息错漏而导致寄失将不获补寄；<br/>
            8) 大赛报名截止后（11月25日）不再接受开设新的大赛专用MT4账户，早前已开设的帐户的交易数据将会继续计算至到12月8日第二阶段赛结束为止；<br/>
            9) 礼品每天限量换领，先到先得，换完即止；<br/>
            10) 每位参赛者只可换领一次，以客户的ID为准；<br/>
            11) 此礼品通过官方渠道采购，由于是定制礼品因此不接受转让、退换、兑换现金或其他礼品的申请；<br/>
            12) 本活动最终解释权归 Doo Prime 所有。
        </p>
    </div>`;

const Activity = (props) => {
    const { language, config, triggerPopup, uuid, isParticipant, isChina, pointsMallLink, friendsInvited, giftAmount, giftEligibility, activityEnd } = props;
    const { t } = useTranslation();

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [language, uuid, isChina]);

    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();
    }, []);


    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    const redirectPage = (action) => {
        switch(action) {
            case "invite-friend":
                if (uuid) {
                    openPopup('invite-friend');
                } else {
                    window.location.href = config.crmUrl;
                }
                break;
            case "redeem-form":
                if (uuid) {
                    if (isParticipant) {
                        if (giftAmount > 0) {
                            if (giftEligibility === 0) {
                                openPopup('redeem-form');
                            } else {
                                let error = '';

                                switch(giftEligibility) {
                                    case 3002:
                                    case 3003:
                                        error = '抱歉，请先满足换领条件后再尝试。';
                                        break;
                                    case 3004:
                                        error = '抱歉，今天的限量礼品已换领完毕，明天再來吧。';
                                        break;
                                }
                                openPopup('info', error);
                            }
                        } else {
                            let error = '';

                            switch(giftEligibility) {
                                case 3002:
                                case 3003:
                                    error = '抱歉，请先满足换领条件后再尝试。';
                                    break;
                                case 3004:
                                    error = '抱歉，今天的限量礼品已换领完毕，明天再來吧。';
                                    break;
                            }
                            openPopup('info', error);
                        }
                    } else {
                        openPopup('info', "您好，满足参赛条件方可换领礼品。");
                    }
                } else {
                    window.location.href = config.crmUrl;
                }
                break;
            case "points-mall":
                if (uuid) {
                    window.location.href = pointsMallLink;
                } else {
                    window.location.href = config.crmUrl;
                }
                break;
        }
    };

    return (
        <div id="activity" className={language}>
            <div className="section-title wow fadeInUp">
                {t('activity.title')}
            </div>
            <div className="content">
                <div className="top-panel wow fadeInUp" data-wow-delay="0.5s">
                    <div className="image">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/dollar-image.png" alt="Dollar"/>
                    </div>
                    <div className="text">
                        <span className="">{t('activity.invite.title')}</span>
                        <div dangerouslySetInnerHTML={{__html: t('activity.invite.desc')}}></div>
                        <button className="card-button" onClick={() => redirectPage("invite-friend")} disabled={activityEnd}>{ activityEnd ? t('activity.invite.end') : t('activity.invite.share')}</button>
                        {
                            uuid &&
                                <span className="amount">{t('activity.invite.invitation') + friendsInvited + t('activity.invite.persons')}</span>
                        }
                        <span className="disclaimer" data-tip={t('activity.invite.tooltip')} data-text-color="#313131" data-background-color="white" data-place="left">{t('activity.invite.terms')}</span>
                    </div>
                </div>
                {
                    uuid && isChina && (language === "sc") &&
                        <div className="bottom-panel desktop">
                            <div className="card wow fadeInUp">
                                <div className="text">
                                    <span>限量尊享礼品</span>
                                    <span>
                                        成功参赛*，即赏 #DooTrader <br/>
                                        限量尊享礼品一份！<br/>
                                        每天限量换领*，换完即止！
                                    </span>
                                    <div className="wrapper">
                                        {
                                            isParticipant ?
                                                ( giftEligibility === 3000 ?
                                                    <button className="card-button" disabled={giftEligibility === 3000 || activityEnd}>{ activityEnd ? t('activity.invite.end') : "已领取" }</button> :
                                                    <button className="card-button" onClick={() => redirectPage("redeem-form")} disabled={activityEnd}>{ activityEnd ? t('activity.invite.end') : "立即兑换" }</button>
                                                ) : <button className="card-button" onClick={() => redirectPage("redeem-form")} disabled={activityEnd}>{activityEnd ? t('activity.invite.end') : "立即兑换"}</button>
                                        }
                                        <span className="amount">今日剩余：{giftAmount}</span>
                                    </div>
                                    <span className="disclaimer" data-tip={tooltip_gift} data-text-color="#313131" data-background-color="white" data-place="bottom">条款及细则</span>
                                </div>
                                <div className="image">
                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/gift.png"/>
                                </div>
                            </div>
                            <div className="card wow fadeInUp">
                                <div className="text">
                                    <span>"理想家" 积分商城</span>
                                    <span>
                                        交易同时累积梦想值，随时换购心仪的时尚礼品！<br/>
                                        交易1手 = 10梦想值
                                    </span>
                                    <div className="wrapper">
                                        <button className="card-button" onClick={() => redirectPage("points-mall")}>查看梦想值</button>
                                    </div>
                                    <span className="disclaimer">*只适用于中国大陆客户</span>
                                </div>
                                <div className="image">
                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-love.png"/>
                                </div>
                            </div>
                        </div>
                }
                {
                    !uuid && (language === "sc") &&
                        <div className="bottom-panel desktop">
                            <div className="card wow fadeInUp">
                                <div className="text">
                                    <span>限量尊享礼品</span>
                                    <span>
                                        成功参赛*，即赏 #DooTrader <br/>
                                        限量尊享礼品一份！<br/>
                                        每天限量换领*，换完即止！
                                    </span>
                                    <div className="wrapper">
                                        <button className="card-button" onClick={() => redirectPage("redeem-form")} disabled={activityEnd}>{ activityEnd ? t('activity.invite.end') : "立即兑换" }</button>
                                    </div>
                                    <span className="disclaimer" data-tip={tooltip_gift} data-text-color="#313131" data-background-color="white" data-place="bottom">条款及细则</span>
                                </div>
                                <div className="image">
                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/gift.png"/>
                                </div>
                            </div>
                            <div className="card wow fadeInUp">
                                <div className="text">
                                    <span>"理想家" 积分商城</span>
                                    <span>
                                        交易同时累积梦想值，随时换购心仪的时尚礼品！<br/>
                                        交易1手 = 10梦想值
                                    </span>
                                    <div className="wrapper">
                                        <button className="card-button" onClick={() => redirectPage("points-mall")}>查看梦想值</button>
                                    </div>
                                    <span className="disclaimer">*只适用于中国大陆客户</span>
                                </div>
                                <div className="image">
                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-love.png"/>
                                </div>
                            </div>
                        </div>
                }
                <div className="bottom-panel mobile">
                    <div className="card wow fadeInUp">
                        <div className="content-wrapper">
                            <div className="image">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/dollar-image.png" alt="Gold"/>
                            </div>
                            <div className="text">
                                <span>{t('activity.invite.title')}</span>
                            </div>
                        </div>
                        <div className="desc" dangerouslySetInnerHTML={{__html: t('activity.invite.desc')}}></div>
                        <div className="footer-wrapper">
                            <button className="card-button" onClick={() => redirectPage("invite-friend")} disabled={activityEnd}>{ activityEnd ? t('activity.invite.end') : t('activity.invite.share') }</button>
                            {   uuid &&
                                    <span className="amount">{t('activity.invite.invitation') + friendsInvited + t('activity.invite.persons')}</span>
                            }
                            <span className="disclaimer" onClick={() => openPopup('info', t('activity.invite.tooltip'))}>{t('activity.invite.terms')}</span>
                        </div>
                    </div>
                    {
                        uuid && isChina && (language === "sc") &&
                            <>
                                <div className="card wow fadeInUp">
                                    <div className="content-wrapper">
                                        <div className="text">
                                            <span>限量尊享礼品</span>
                                            <span>
                                            成功参赛*，即赏 #DooTrader <br/>
                                            限量尊享礼品一份！<br/>
                                            每天限量换领*，换完即止！
                                        </span>
                                        </div>
                                        <div className="image">
                                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/gift.png"/>
                                        </div>
                                    </div>
                                    <div className="footer-wrapper">
                                        {
                                            isParticipant ? (
                                                giftEligibility === 3000 ? <button className="card-button" disabled={activityEnd}>{ activityEnd ? t('activity.invite.end') : "Gift Redeemed" }</button> : <button className="card-button" onClick={() => redirectPage("redeem-form")} disabled={activityEnd}>{ activityEnd ? t('activity.invite.end') : "立即兑换" }</button>
                                            ) : <button className="card-button" onClick={() => redirectPage("redeem-form")} disabled={activityEnd}>立即兑换</button>
                                        }
                                        <div className="amount">今日剩余：{giftAmount}</div>
                                        <span className="disclaimer" onClick={() => openPopup('info', tooltip_gift)}>*只适用于中国大陆客户</span>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp">
                                    <div className="content-wrapper">
                                        <div className="text">
                                            <span>"理想家" 积分商城</span>
                                            <span>
                                                交易同时累积梦想值，随时换购心仪的时尚礼品！<br/><br/>
                                                交易1手 = 10梦想值
                                            </span>
                                        </div>
                                        <div className="image">
                                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-love.png"/>
                                        </div>
                                    </div>
                                    <div className="footer-wrapper">
                                        <button className="card-button" onClick={() => redirectPage("points-mall")}>查看梦想值</button>
                                        <span className="disclaimer">*只适用于中国大陆客户</span>
                                    </div>
                                </div>
                            </>
                    }
                    {
                        !uuid && (language === "sc") &&
                        <>
                            <div className="card wow fadeInUp">
                                <div className="content-wrapper">
                                    <div className="text">
                                        <span>限量尊享礼品</span>
                                        <span>
                                            成功参赛*，即赏 #DooTrader <br/>
                                            限量尊享礼品一份！<br/>
                                            每天限量换领*，换完即止！
                                        </span>
                                    </div>
                                    <div className="image">
                                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/gift.png"/>
                                    </div>
                                </div>
                                <div className="footer-wrapper">
                                    <button className="card-button" onClick={() => redirectPage("redeem-form")} disabled={activityEnd}>{ activityEnd ? t('activity.invite.end') : "立即兑换" }</button>
                                    <span className="disclaimer" onClick={() => openPopup('info', tooltip_gift)}>条款及细则</span>
                                </div>
                            </div>
                            <div className="card wow fadeInUp">
                                <div className="content-wrapper">
                                    <div className="text">
                                        <span>"理想家" 积分商城</span>
                                        <span>
                                                交易同时累积梦想值，随时换购心仪的时尚礼品！<br/><br/>
                                                交易1手 = 10梦想值
                                            </span>
                                    </div>
                                    <div className="image">
                                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-love.png"/>
                                    </div>
                                </div>
                                <div className="footer-wrapper">
                                    <button className="card-button" onClick={() => redirectPage("points-mall")}>查看梦想值</button>
                                    <span className="disclaimer">*只适用于中国大陆客户</span>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <ReactTooltip html={true} effect="solid" />
        </div>
    )
}

export default Activity;
