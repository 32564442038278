import React, {useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import { WOW } from "wowjs";

import Table from '../common/table';
import Countdown from '../common/countdown';

import 'react-tabs/style/react-tabs.css';
import './scss/ranking.scss';
import axios from "axios";
import scroll from "react-scroll";

const Ranking = (props) => {
    const { t } = useTranslation();
    const { language, uuid, config, expandTerms, isChina, competitionProgress, competitionDates } = props;
    const [competitionRound, setCompetitionRound] = useState(0);
    const [accountType, setAccountType] = useState(0);
    const [ranking, setRanking] = useState([]);
    const [timestamp, setTimestamp] = useState(null);

    useEffect(() => {
        switch(competitionProgress) {
            case 0 :
            case 1  :
            case 2  :
                setCompetitionRound(0);
                break;
            case 3  :
            case 4  :
                setCompetitionRound(1);
                break;
            case 5  :
                setCompetitionRound(2);
                break;
            default :
                break;
        }
    }, [competitionProgress]);

    useEffect(() => {
        const getRankingData = async() => {
            if (Object.keys(config).length) {
                let params = {};

                switch(competitionRound) {
                    case 0:
                    case 1:
                    case 2:
                        params = {
                            accountType: accountType ? config.rankingCredential.masterId : config.rankingCredential.classicId,
                            competitionRounds: competitionRound + 1,
                            page: 1,
                            pagesize: 15,
                            serverId: config.rankingCredential.serverId,
                            sort: 1,
                            sortName: 'rate_of_return'
                        };
                        break;
                    case 3:
                        params = {
                            rankType: 'others',
                            page: 1,
                            pagesize: 10
                        }
                        break;
                }

                const rank = await axios({
                    url: '/api/v1/activity/competition/rankingData',
                    method: 'GET',
                    params
                });

                if (rank.data.data.code === 0) {
                    const data = rank.data.data.data.map((value, index) => ({...value, number:
                            index === 0 ? 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/ranking-first.png' :
                                (index === 1 ? 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/ranking-second.png' :
                                    (index === 2 ? 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/ranking-third.png' : index + 1))}));
                    data.forEach(item => {
                        let image = item.uhead ? JSON.parse(item.uhead).oss : '';
                        const name = item.name && item.name.length > 3 ? item.name.slice(0, -3) + Array(4).join("X") : item.name;

                        item.name = {image, name};
                    });
                    setRanking(data);

                    if (rank.data.data.data.length > 0) {
                        setTimestamp(moment(rank.data.data.data[0].update_time).format('YYYY/MM/DD HH:mm:ss'));
                    }
                } else {
                    setRanking([]);
                }
            }
        };

        if (config) {
            getRankingData();
        }
    }, [config, competitionRound, accountType]);
    
    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'number',
                Cell: ({ cell: { value }}) => {
                    return Number.isInteger(value) ? <span>{'#' + value}</span> : <img src={value} width="49" height="52" alt="Rank"/>
                }
            },
            {
                Header: t('ranking.contestant'),
                accessor: 'name',
                Cell: ({ cell: { value }}) => {
                    return value.image ? <div className="contestant"><img src={value.image} width="72" height="80" alt="Profile"/><span>{value.name}</span></div> : <span>{value.name}</span>;
                }
            },
            {
                Header: t('ranking.country'),
                accessor: 'country',
                Cell: ({ cell: { value }}) => {
                    return value && value.length ? <img src={'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/flags/' + value + '.svg'} width="30" height="20" alt="Flag"/> : value;
                }
            },
            {
                Header: t('ranking.mt4'),
                accessor: 'login',
                Cell: ({ cell: { value }}) => {
                    return value && (value.toString().length > 3) ? value.toString().slice(0, -3) + Array(4).join("X") : value.toString();
                }
            },
            {
                Header: t('ranking.lots'),
                accessor: 'volume',
                Cell: ({ cell: { value }}) => {
                    return Number(value).toFixed(2);
                }
            },
            {
                Header: t('ranking.worth'),
                accessor: 'equity',
                Cell: ({ cell: { value }}) => {
                    return "USD " + Number(value).toFixed(2);
                }
            },
            {
                Header: t('ranking.yield'),
                accessor: 'rate_of_return',
                Cell: ({ cell: { value }}) => {
                    return Number(value * 100).toFixed(2) + "%";
                }
            },
            {
                Header: t('ranking.drawdown'),
                accessor: 'maxdown',
                Cell: ({ cell: { value }}) => {
                    return Number(value * 100).toFixed(2) + "%";
                }
            }
        ],[language]);

    const others = useMemo(
        () => [
            {
                Header: '',
                accessor: 'number',
                Cell: ({ cell: { value }}) => {
                    return Number.isInteger(value) ? <span>{'#' + value}</span> : <img src={value} width="49" height="52" alt="Rank"/>
                }
            },
            {
                Header: t('ranking.contestant'),
                accessor: 'name',
                Cell: ({ cell: { value }}) => {
                    return value.image ? <div className="contestant"><img src={value.image} width="72" height="80" alt="Profile"/><span>{value.name}</span></div> : <span>{value.name}</span>;
                }
            },
            {
                Header: t('ranking.country'),
                accessor: 'country',
                Cell: ({ cell: { value }}) => {
                    return value && value.length ? <img src={'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/flags/' + value + '.svg'} width="30" height="20" alt="Flag"/> : value;
                }
            },
            {
                Header: t('ranking.mt4'),
                accessor: 'login',
                Cell: ({ cell: { value }}) => {
                    return value && (value.toString().length > 3) ? value.toString().slice(0, -3) + Array(4).join("X") : value.toString();
                }
            },
            {
                Header: t('ranking.lots'),
                accessor: 'volume',
                Cell: ({ cell: { value }}) => {
                    return Number(value).toFixed(2);
                }
            }
        ],[language]);

    const scrollToTerms = () => {
        scroll.scroller.scrollTo('ranking-rules', {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100
        });
        expandTerms();
    };

    /* Competition Progress Markers */
    // 0 - Before Round 1 starts
    // 1 - Round 1 ongoing
    // 2 - Round 1 ended, before Round 2 starts
    // 3 - Round 2 ongoing
    // 4 - Round 2 ended, before Round 3 starts
    // 5 - Round 3 ongoing
    return (
        <div id="ranking">
            <div className="section-title wow fadeInUp">
                <span>{t('ranking.title')} <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/live.png" alt="Live"/></span>
            </div>
            {
                timestamp &&
                    <div className="updated">
                        {t('ranking.updated')} { timestamp } (UTC+0)
                    </div>
            }
            <div>
                <Tabs className="outer-tabs wow fadeInUp" selectedIndex={competitionRound} onSelect={index => setCompetitionRound(index)}>
                    <TabList>
                        <Tab>{t('ranking.round_1')}</Tab>
                        <Tab>{t('ranking.round_2')}</Tab>
                        <Tab>{t('ranking.finals')}</Tab>
                        {
                            language === 'sc' && uuid && isChina &&
                                <Tab>
                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/ranking-heart.png" alt="Heart"/>
                                    慈善交易大使
                                </Tab>
                        }
                        {
                            language === 'sc' && !uuid &&
                                <Tab>
                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/ranking-heart.png" alt="Heart"/>
                                    慈善交易大使
                                </Tab>
                        }
                    </TabList>

                    <TabPanel>
                        {
                            competitionProgress === 0 &&
                                <>
                                    <div className="event-title wow fadeInUp">{t('ranking.countdown.round_1')}</div>
                                    <Countdown date={competitionDates.round_1.start}/>
                                </>
                        }
                        {
                            competitionProgress >= 1 && (
                                <Tabs className="inner-tabs" selectedIndex={accountType} onSelect={index => setAccountType(index)}>
                                    <div className="inner-tab-wrapper">
                                        <TabList>
                                            <Tab>{t('ranking.classic')}</Tab>
                                            <Tab>{t('ranking.master')}</Tab>
                                        </TabList>
                                        <span onClick={() => scrollToTerms()}>{t('ranking.calculate')}</span>
                                    </div>

                                    <TabPanel>
                                        <Table columns={columns} data={ranking} />
                                    </TabPanel>
                                    <TabPanel>
                                        <Table columns={columns} data={ranking} />
                                    </TabPanel>
                                </Tabs>
                            )
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            competitionProgress < 3 &&
                                <>
                                    <div className="event-title wow fadeInUp">{t('ranking.countdown.round_2')}</div>
                                    <Countdown date={competitionDates.round_2.start}/>
                                </>
                        }
                        {
                            competitionProgress >= 3 && (
                                <Tabs className="inner-tabs" selectedIndex={accountType} onSelect={index => setAccountType(index)}>
                                    <div className="inner-tab-wrapper">
                                        <TabList>
                                            <Tab>{t('ranking.classic')}</Tab>
                                            <Tab>{t('ranking.master')}</Tab>
                                        </TabList>
                                        <span onClick={() => scrollToTerms()}>{t('ranking.calculate')}</span>
                                    </div>

                                    <TabPanel>
                                        <Table columns={columns} data={ranking} />
                                    </TabPanel>
                                    <TabPanel>
                                        <Table columns={columns} data={ranking} />
                                    </TabPanel>
                                </Tabs>
                            )
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            competitionProgress < 5 &&
                                <>
                                    <div className="event-title wow fadeInUp">{t('ranking.countdown.finals')}</div>
                                    <Countdown date={competitionDates.round_3.start}/>
                                </>

                        }
                        {
                            competitionProgress >= 5 && (
                                <Tabs className="inner-tabs" selectedIndex={accountType} onSelect={index => setAccountType(index)}>
                                    <div className="inner-tab-wrapper">
                                        <TabList>
                                            <Tab>{t('ranking.classic')}</Tab>
                                            <Tab>{t('ranking.master')}</Tab>
                                        </TabList>
                                        <span onClick={() => scrollToTerms()}>{t('ranking.calculate')}</span>
                                    </div>

                                    <TabPanel>
                                        <Table columns={columns} data={ranking} />
                                    </TabPanel>
                                    <TabPanel>
                                        <Table columns={columns} data={ranking} />
                                    </TabPanel>
                                </Tabs>
                            )
                        }
                    </TabPanel>
                    {
                        language === 'sc' && uuid && isChina &&
                            <TabPanel>
                                <Tabs className="inner-tabs">
                                    <Table columns={others} data={ranking} />
                                </Tabs>
                            </TabPanel>
                    }
                    {
                        language === 'sc' && !uuid &&
                        <TabPanel>
                            <Tabs className="inner-tabs">
                                <Table columns={others} data={ranking} />
                            </Tabs>
                        </TabPanel>
                    }
                </Tabs>
            </div>
        </div>
    )
}

export default Ranking;
