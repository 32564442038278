import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import { WOW } from "wowjs";

import './scss/guide.scss';

const responsive = {
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Guide = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();
    }, []);

    return (
        <div id="guide">
            <div className="section-title wow fadeInUp">
                {t('guide.title')}
            </div>
            <div className="content desktop" >
                <div className="card wow fadeInUp" data-wow-delay="0.5s">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/guide_4.png"/>
                    <div content="text">
                        <span>{t('guide.subtitle-1')}</span>
                        <span>{t('guide.desc-1')}</span>
                    </div>
                </div>
                <div className="card wow fadeInUp" data-wow-delay="1s">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/guide_3.png"/>
                    <div content="text">
                        <span>{t('guide.subtitle-2')}</span>
                        <span>{t('guide.desc-2')}</span>
                    </div>
                </div>
                <div className="card wow fadeInUp" data-wow-delay="1.5s">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/guide_2.png"/>
                    <div content="text">
                        <span>{t('guide.subtitle-3')}</span>
                        <span>{t('guide.desc-3')}</span>
                    </div>
                </div>
                <div className="card wow fadeInUp" data-wow-delay="2s">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/guide.png"/>
                    <div content="text">
                        <span>{t('guide.subtitle-4')}</span>
                        <span>{t('guide.desc-4')}</span>
                    </div>
                </div>
            </div>
            <div className="content mobile">
                <Carousel
                    responsive={responsive}
                    showDots={true}
                    arrows={false}
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="guide-carousel-container"
                    dotListClass="guide-custom-dot-list"
                    itemClass="carousel-item-padding-40-px"
                >
                    <div className="card wow fadeInUp">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/guide_4.png"/>
                        <div content="text">
                            <span>{t('guide.subtitle-1')}</span>
                            <span>{t('guide.desc-1')}</span>
                        </div>
                    </div>
                    <div className="card wow fadeInUp">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/guide_3.png"/>
                        <div content="text">
                            <span>{t('guide.subtitle-2')}</span>
                            <span>{t('guide.desc-2')}</span>
                        </div>
                    </div>
                    <div className="card wow fadeInUp">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/guide_2.png"/>
                        <div content="text">
                            <span>{t('guide.subtitle-3')}</span>
                            <span>{t('guide.desc-3')}</span>
                        </div>
                    </div>
                    <div className="card wow fadeInUp">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/guide.png"/>
                        <div content="text">
                            <span>{t('guide.subtitle-4')}</span>
                            <span>{t('guide.desc-4')}</span>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}

export default Guide;

