import React from "react";

const Error = (props) => {
    const { close } = props;

    return (
        <div className="modal-container">
            <div className="modal">
                <header className="modal-header">
                    <h2 className="modal-header-title">Error</h2>
                    <button className="close" onClick={close}>
                        X
                    </button>
                </header>
                <main className="modal-content">
                    This is Modal Content
                </main>
                <footer className="modal-footer">
                    <button className="modal-close" onClick={close}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    )
}

export default Error;
