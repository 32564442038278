import React from 'react';
import "./index.scss";

const Mask = () => {
    return (
        <div className="mask">
            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-tech/images/doo_tech_MaskTip.png" alt="WeChat Mask" />
        </div>
    );
}

export default Mask;
