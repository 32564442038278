import React from 'react';

const RedeemSuccess = (props) => {
    const { close, content } = props;

    return (
        <div className="modal-container redeem-success">
            <div className="modal">
                <button className="close" onClick={close}>
                    X
                </button>
                <header className="modal-header">
                    
                    <div className="popup-wrapper">
                        <h5 className="title">恭喜您！您已成功申请换领</h5>
                        <p className="content gold">限量 #DooTrader 尊享礼品 一份！</p>
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/gift.png" className="gift"/>
                        <p className="content code_title">兑换码</p>
                        <p className="code">{content}</p>
                    </div>
                </header>
            </div>
        </div>
    )
}

export default RedeemSuccess;
