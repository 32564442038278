import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import scroll from 'react-scroll';
import { useTranslation } from "react-i18next";
import { WOW } from "wowjs";

import ProgressBar from '../common/progress-bar';
import Countdown from '../common/countdown';

import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Header = (props) => {
    const { t } = useTranslation();
    const { language, config, triggerPopup, prizePool, amountBar, donationSum, bannerButtonText, bannerButtonRedirection, competitionProgress, countdownTitle, countdownText, countdownTimer, bannerInitial, bannerImg, bannerTitle, bannerDesc, bannerSecondDesc } = props;
    const [incrementalSum, setIncrementalSum] = useState([{
        label: '',
        value: 0
    }]);

    const openPopup = (type, content = null) => {
        triggerPopup(type);
    };

    useEffect(() => {
        const wow = new WOW(
            {
                live: false,
            }
        );
        wow.init();
    }, []);

    useEffect(() => {
        let i = 0;
        let amount = amountBar[0].value > 100 ? 100 : amountBar[0].value;

        let interval = setInterval(function() {
            if (i <= amount) {
                setIncrementalSum([{
                    label: '',
                    value: i
                }]);
                i++;
            }
            else {
                clearInterval(interval);
            }
        }, 25);


    }, [amountBar])

    const buttonAction = (action) => {
        switch(action) {
            case 'crm_lead_form':
                openPopup('crm-register');
                break;
            case 'crm_mt_acc':
                window.location.href = config.crmUrl + "/contest";
                break;
            case 'results_view':
                scroll.scroller.scrollTo('user-results', {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: -120
                });
                break;
        }
    };

    return (
        <div className={"banner " + language}>
            <Carousel
                responsive={responsive}
                showDots={true}
                arrows={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={6000}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list"
                itemClass="carousel-item-padding-40-px"
            >
                {
                    (language === 'sc') &&
                    <div className="banner-container banner-0">
                        <div className="banner-text">
                            <div id="title" className="wow fadeInUp">
                                <span>{t('banner.title-0')}</span>
                                {t('banner.desc-0')}
                            </div>
                            <div className="group wow fadeInUp">
                                <h4>大师组</h4>
                                <p><img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/25.png" alt="No. 1"/> Li GuXXX  收益率 317.35%  净值 41,735.28 美金</p>
                            </div>
                            <div className="group wow fadeInUp">
                                <h4>经典组</h4>
                                <p><img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/25.png" alt="No. 1"/> Cai XXX  收益率 226.22%  净值 6534.3 美金</p>
                            </div>
                            {
                                bannerButtonText &&
                                    <button className="banner-button wow fadeInUp" onClick={() => buttonAction(bannerButtonRedirection)}>{bannerButtonText}</button>
                            }
                        </div>
                    </div>
                }
                <div className="banner-container banner-1" style={{background: '#0a090a url(' + bannerImg +') no-repeat center', backgroundSize: 'cover'}}>
                    <div className="banner-text">
                        {
                            bannerInitial && <>
                                <div id="title" className="wow fadeInUp">
                                    {bannerTitle}<br/>
                                    {bannerDesc}
                                </div>
                                <div id="duration" className="wow fadeInUp">
                                    <span>{t('banner.registration')}</span>
                                    <span>{t('banner.competition')}</span>
                                    <span>{t('banner.gmt')}</span>
                                </div>
                            </>
                        }
                        {
                            !bannerInitial && <>
                                <div id="title" className="wow fadeInUp" dangerouslySetInnerHTML={{__html: bannerTitle}} />
                                <div id="desc" className="wow fadeInUp" dangerouslySetInnerHTML={{__html: bannerSecondDesc}} />
                            </>
                        }
                        {
                            bannerButtonText &&
                            <button className="banner-button wow fadeInUp" onClick={() => buttonAction(bannerButtonRedirection)}>{bannerButtonText}</button>
                        }
                    </div>
                </div>
                <div className="banner-container banner-2">
                    <div className="banner-text">
                        <div id="title" className="wow fadeInUp">
                            {t('banner.title-2')}<br/>
                            <span className="number">{t('banner.number')}</span><br/>
                            <span>{t('banner.title-2-1')}</span>
                        </div>
                        <div id="desc" className="wow fadeInUp">{t('banner.desc-2')}</div>
                        {
                            bannerButtonText &&
                            <button className="banner-button wow fadeInUp" onClick={() => buttonAction(bannerButtonRedirection)}>{bannerButtonText}</button>
                        }
                    </div>
                </div>
                {/*<div className="banner-container banner-3">*/}
                {/*    <div className="banner-text wow fadeInUp">*/}
                {/*        <button className="banner-button" onClick={() => redirectPage('winwithfriends')}>Test 3</button>*/}
                {/*    </div>*/}
                {/*    <div className="banner-title wow fadeInUp">{t('banner.prize.title')}</div>*/}
                {/*</div>*/}
            </Carousel>
            <div className="current-count">
                <div className="current-count-container">
                    <div className="amount">
                        <div className="banner-title">{t('banner.prize.title')}</div>
                        <div id="total" className="wow fadeInUp">$ <span>{Math.round(prizePool).toLocaleString()}</span></div>
                        <ProgressBar data={incrementalSum} />
                        <div className="label desktop wow fadeInUp">
                            <span>{t('banner.prize.pool')}</span>
                            <span>$ 80,000</span>
                            <span>$ 90,000</span>
                            <span>$ 100,000</span>
                            <span>$ 110,000</span>
                            <span>$ 120,000</span>
                            <span>$ 130,000</span>
                            <span>$ 140,000</span>
                            <span>$ 150,000</span>
                        </div>
                        <div className="label mobile wow fadeInUp">
                            <span>{t('banner.prize.pool')}</span>
                            <span>$ 80,000</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>$ 150,000</span>
                        </div>
                    </div>
                    <div className="box wow fadeInUp">
                        <span>
                            {t('banner.prize.details')}
                        </span>
                        <span>
                            {t('banner.prize.desc-1')}
                        </span>
                        {
                            competitionProgress === 0 &&
                            <span dangerouslySetInnerHTML={{__html: t('banner.prize.desc-2') + t('banner.prize.link')}}></span>
                        }
                        {   competitionProgress > 0 &&
                        <span dangerouslySetInnerHTML={{__html: t('banner.prize.desc-2') + t('banner.prize.donation') + donationSum + t('banner.prize.dot') + t('banner.prize.link')}}></span>
                        }
                    </div>
                    {
                        countdownTitle &&
                        <div className="title wow fadeInUp">{countdownTitle}</div>
                    }
                    {
                        countdownText &&
                        <div className="event-desc wow fadeInUp">{countdownText}</div>
                    }
                    {
                        countdownTimer &&
                        <Countdown date={countdownTimer}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header;
