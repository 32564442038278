import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import axios from 'axios';
import scroll from 'react-scroll';
import { useTranslation } from "react-i18next";

import Header from './header';
import Guide from '../sections/guide';
import UserResults from '../sections/user-results';
import Prize from '../sections/prize';
import Activity from '../sections/activity';
import Ranking from '../sections/ranking';
import Terms from '../sections/terms';
import News from '../sections/news';
import Investment from '../sections/investment';

const Content = (props) => {
    const { t } = useTranslation();
    const { language, config, triggerPopup, updateTimer, uuid, isParticipant, isChina, isKorea, pointsMallLink, userResults, friendsInvited, giftAmount, giftEligibility } = props;
    const [scrollPosition, setScrollPosition] = useState(null);
    const [prizePool, setPrizePool] = useState(0);
    const [amountBar, setAmountBar] = useState([{
        label: '',
        value: 0
    }]);
    const [donationSum, setDonationSum] = useState(0);
    const [bannerButtonText, setBannerButtonText] = useState('');
    const [bannerButtonRedirection, setBannerButtonRedirection] = useState('');
    const [timeOffset, setTimeOffset] = useState(0);
    const [countdownTitle, setCountdownTitle] = useState(null);
    const [countdownText, setCountdownText] = useState(null);
    const [countdownTimer, setCountdownTimer] = useState(null);
    const [competitionProgress, setCompetitionProgress] = useState(0);
    const [menuJoin, setMenuJoin] = useState(null);
    const [menuJoinText, setMenuJoinText] = useState('');
    const [displayMenu, setDisplayMenu] = useState(true);
    const [displayActivityPanel, setDisplayActivityPanel] = useState(true);
    const [activityEnd, setActivityEnd] = useState(false);
    const [refreshState, setRefreshState] = useState(false);
    const [loginState, setLoginState] = useState(false);
    const [anniversaryEnd, setAnniversaryEnd] = useState(false);
    const [bannerInitial, setBannerInitial] = useState(true);
    const [bannerImg, setBannerImg] = useState(null);
    const [bannerTitle, setBannerTitle] = useState(t('banner.initial.title'));
    const [bannerDesc, setBannerDesc] = useState(t('banner.initial.desc'));
    const [bannerSecondDesc, setBannerSecondDesc] = useState(null);
    let intervalRef = useRef(null);

    const termRef = useRef();

    const dates = {
        'registration': {
            'start': '2021-10-15 00:06:00+00:00',
            'end': '2021-11-25 23:59:59+00:00'
        },
        'event': {
            'round_1': {
                'start': '2021-10-15 00:06:00+00:00',
                'end': '2021-11-10 23:59:59+00:00'
            },
            'round_2': {
                'start': '2021-11-15 00:06:00+00:00',
                'end': '2021-12-08 23:59:59+00:00'
            },
            'round_3': {
                'start': '2021-12-13 00:06:00+00:00',
                'end': '2021-12-17 23:59:59+00:00'
            },
            'anniversary': {
                'end': '2022-01-01 00:00:00+08:00'
            },
            'banner': {
                'initial': {
                    'desktop': 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/home-banner-1.png',
                    'mobile': 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/mobile-home-banner-1.png'
                },
                'first': {
                    'date': '2021-12-18 08:00:00+08:00',
                    'desktop': 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/1218-banner-web.png',
                    'mobile': 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/1218-banner-mobile.png',
                    'title': t('banner.first.title'),
                    'desc': t('banner.first.desc')
                },
                'second': {
                    'date': '2021-12-21 08:00:00+08:00',
                    'desktop': 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/1218-banner-web.png',
                    'mobile': 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/1218-banner-mobile.png',
                    'title': t('banner.second.title'),
                    'desc': t('banner.second.title')
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', updateScroll);
    },[scrollPosition]);

    useEffect(() => {
        const getAmountData = async() => {
            const amount = await axios({
                url: '/api/v1/activity/prizePool/totalAmount/',
                method: 'GET'
            });

            if(amount.data.data.code === 0) {
                setPrizePool(amount.data.data.data.prizePool);
                setAmountBar( [{
                    label: '',
                    value: Math.round(amount.data.data.data.prizePool - 70000) / 80000 * 100    // (total amount - 70000)/80000 * 100
                }]);
                setDonationSum(amount.data.data.data.donationSum);
            }
        };

        getTimeOffset();
        getAmountData();
        changeContent();
    }, []);

    useEffect(() => {
        if (uuid && !loginState) {
            setLoginState(true);
        }

        intervalRef.current = setInterval(() => {
            changeContent();
            checkCookie();
        }, 1000);
        return () => clearInterval(intervalRef.current);
    }, [uuid, isParticipant, userResults]);

    useEffect(() => {
        updatePopupCountdown(countdownText, countdownTimer);
    }, [countdownText, countdownTimer]);

    const checkCookie = () => {
        const cookie = localStorage.getItem('uuid');

        if (cookie) {
            const item = JSON.parse(cookie);
            const now = new Date();

            if (now.getTime() > item.expiry) {
                localStorage.removeItem(cookie);

                if (loginState && !refreshState) {
                    window.location.reload(false);
                    setRefreshState(true);
                }
            }
        }
    }

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    const updatePopupCountdown = (text, timer) => {
        updateTimer(text, timer);
    };

    const updateScroll = () => {
        setScrollPosition(window.scrollY);
    };

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const checkJoinRedirection = () => {
        switch(menuJoin) {
            case "crm_lead_form":
                openPopup("crm-register");
                break;
            case "crm_mt_account":
                window.location.href = config.crmUrl + "/contest";
                break;
        }
    };

    const getServerTime = () => {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open('HEAD',window.location.href.toString(),false);
        xmlHttp.setRequestHeader("Content-Type", "text/html");
        xmlHttp.send('');
        return xmlHttp.getResponseHeader("Date");
    };

    const getTimeOffset = () => {
        const serverTime = moment(new Date(getServerTime()));
        setTimeOffset(serverTime.diff(moment()));
    };

    const checkIsMobile = () => {
        return !!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/);
    };

    const changeContent = () => {
        const now = moment(moment().add(timeOffset)).utc();
        // const now = moment(moment().add(timeOffset)).utc().utcOffset('GMT+02:00');
        // console.log(now.format('YYYY-MM-DD hh:mm:ss A'));    // check current server time in UTC

        if(now.isBefore(dates.event.banner.first.date)) {
            setBannerImg(checkIsMobile() ? dates.event.banner.initial.mobile : dates.event.banner.initial.desktop);
        } else {
            setBannerInitial(false);
            if (now.isBetween(dates.event.banner.first.date, dates.event.banner.second.date, undefined, '[)')) {
                setBannerImg(checkIsMobile() ? dates.event.banner.first.mobile : dates.event.banner.first.desktop);
                setBannerTitle(dates.event.banner.first.title);
                setBannerDesc(dates.event.banner.first.desc);
                setBannerSecondDesc(t('banner.first.desc'));
            } else if (now.isAfter(dates.event.banner.second.date)) {
                setBannerImg(checkIsMobile() ? dates.event.banner.second.mobile : dates.event.banner.second.desktop);
                setBannerTitle(dates.event.banner.second.title);
                setBannerDesc(dates.event.banner.second.desc);
                setBannerSecondDesc(t('banner.second.desc'));
            }
        }

        /* set countdown timer, ranking data */
        if (now.isBefore(dates.event.round_1.start)) {
            // set countdown to Round 1 start
            setCountdownTitle(t('banner.countdown.round-1.before.title'));
            setCountdownText(t('banner.countdown.round-1.before.desc'));
            setCountdownTimer(dates.event.round_1.start);
            setCompetitionProgress(0);
        } else if (now.isBetween(dates.event.round_1.start, dates.event.round_1.end, undefined, '[)')) {
            // set countdown to Round 1 end
            setCountdownTitle(t('banner.countdown.round-1.ongoing.title'));
            setCountdownText(t('banner.countdown.round-1.ongoing.desc'));
            setCountdownTimer(dates.event.round_1.end);
            setCompetitionProgress(1);
        } else if (now.isBetween(dates.event.round_1.end, dates.event.round_2.start, undefined, '[)')) {
            // set countdown to Round 2 start
            setCountdownTitle(t('banner.countdown.round-2.before.title'));
            setCountdownText(t('banner.countdown.round-2.before.desc'));
            setCountdownTimer(dates.event.round_2.start);
            setCompetitionProgress(2);
        } else if (now.isBetween(dates.event.round_2.start, dates.event.round_2.end, undefined, '[)')) {
            // set countdown to Round 2 end
            setCountdownTitle(t('banner.countdown.round-2.ongoing.title'));
            setCountdownText(t('banner.countdown.round-2.ongoing.desc'));
            setCountdownTimer(dates.event.round_2.end);
            setCompetitionProgress(3);
        } else if (now.isBetween(dates.event.round_2.end, dates.event.round_3.start, undefined, '[)')) {
            // set countdown to Round 3 start
            setCountdownTitle(t('banner.countdown.finals.before.title'));
            setCountdownText(t('banner.countdown.finals.before.desc'));
            setCountdownTimer(dates.event.round_3.start);
            setCompetitionProgress(4);
        } else if (now.isBetween(dates.event.round_3.start, dates.event.round_3.end, undefined, '[)')) {
            // set countdown to Round 3 end
            setCountdownTitle(t('banner.countdown.finals.ongoing.title'));
            setCountdownText(t('banner.countdown.finals.ongoing.desc'));
            setCountdownTimer(dates.event.round_3.end);
            setCompetitionProgress(5);
        } else {
            // hide countdown
            setCompetitionProgress(5);
            setCountdownTitle(null);
            setCountdownText(null);
            setCountdownTimer(null);
            setDisplayActivityPanel(false);
            setDisplayMenu(false);

            if (now.isAfter(dates.event.anniversary.end)) {
                setAnniversaryEnd(true);
            }
        }

        /* set banner button, floating menu */
        if (now.isBefore(dates.registration.end)) {
            if (uuid) {
                if (now.isAfter(dates.event.round_1.start)) {
                    if (isParticipant) {
                        if (userResults.length > 0) {
                            setBannerButtonRedirection('results_view');
                            setBannerButtonText(t('banner.view'));
                        } else {
                            setBannerButtonText(null);
                        }

                        setMenuJoin(null);
                    } else {
                        // for login user & have NOT joined competition: banner join now button (click to redirect to CRM MT account page)
                        // floating menu for login user & have NOT joined competition: join now option (click to redirect to CRM MT account page)
                        setBannerButtonRedirection('crm_mt_acc');
                        setBannerButtonText(t('banner.join'));
                        setMenuJoinText(t('banner.join'));
                        setMenuJoin('crm_mt_account');
                    }
                } else {
                    setBannerButtonText(null);
                    setMenuJoin(null);
                }
            } else {
                // for non-login user: banner join now button (click to redirect to CRM lead form)
                // floating menu for non-login user: join now option (click to redirect to CRM lead form)
                setBannerButtonRedirection('crm_lead_form');
                setBannerButtonText(t('banner.join'));
                setMenuJoin('crm_lead_form');

                if (now.isBefore(dates.event.round_3.end)) {
                    setMenuJoinText(t('banner.join'));
                } else {
                    setMenuJoinText(t('banner.nextRound'));
                }
            }
        } else {
            setActivityEnd(true);

            if (uuid) {
                if (now.isAfter(dates.event.round_1.start)) {
                    // floating menu for login user: hide join now option
                    if (isParticipant && (userResults.length > 0)) {
                        setBannerButtonRedirection('results_view');
                        setBannerButtonText(t('banner.view'));
                    } else {
                        setBannerButtonText(null);
                    }

                    if (now.isBefore(dates.event.round_3.end)) {
                        setMenuJoin(null);
                    }
                }
            } else {
                setMenuJoin('crm_lead_form');

                if (now.isBefore(dates.event.round_3.end)) {
                    // for non-login user: banner pre-registration button (click to redirect to CRM lead form)
                    setBannerButtonRedirection('crm_lead_form');
                    setBannerButtonText(t('banner.preRegister'));
                    setMenuJoinText(t('banner.preRegister'));
                } else {
                    // for non-login user: banner join next round button (click to redirect to CRM lead form)
                    // floating menu for non-login user: hide other activities and join now option
                    setBannerButtonRedirection('crm_lead_form');
                    setBannerButtonText(t('banner.nextRound'));
                    setMenuJoinText(t('banner.nextRound'));
                }
            }
        }
    };

    const expandRankingTerms = () => {
        termRef.current.expandRankingTerms();
    }

    const expandPrizeTerms = () => {
        termRef.current.expandPrizeTerms();
    }

    return (
        <div className="content-section">
            <Header language={language}
                    config={config}
                    triggerPopup={openPopup.bind(this)}
                    prizePool={prizePool}
                    amountBar={amountBar}
                    donationSum={donationSum}
                    bannerButtonText={bannerButtonText}
                    bannerButtonRedirection={bannerButtonRedirection}
                    competitionProgress={competitionProgress}
                    countdownTitle={countdownTitle}
                    countdownText={countdownText}
                    countdownTimer={countdownTimer}
                    bannerInitial={bannerInitial}
                    bannerImg={bannerImg}
                    bannerTitle={bannerTitle}
                    bannerDesc={bannerDesc}
                    bannerSecondDesc={bannerSecondDesc} />
            <div className="content-container">
                {
                    !isParticipant &&
                    <Guide />
                }
                {
                    competitionProgress > 0 && uuid && isParticipant && (userResults.length > 0) &&
                    <UserResults config={config}
                                 userResults={userResults}
                                 competitionProgress={competitionProgress}
                                 triggerPopup={openPopup.bind(this)}/>
                }
                <Prize triggerTermsExpand={expandPrizeTerms} language={language}/>
                {
                    displayActivityPanel &&
                    <Activity language={language}
                              config={config}
                              triggerPopup={openPopup.bind(this)}
                              uuid={uuid}
                              isParticipant={isParticipant}
                              isChina={isChina}
                              pointsMallLink={pointsMallLink}
                              friendsInvited={friendsInvited}
                              giftAmount={giftAmount}
                              giftEligibility={giftEligibility}
                              activityEnd={activityEnd}/>
                }
                <Ranking language={language}
                         uuid={uuid}
                         isChina={isChina}
                         config={config}
                         competitionProgress={competitionProgress}
                         competitionDates={dates.event}
                         expandTerms={expandRankingTerms}/>
                <Terms language={language} ref={termRef}/>
                <News language={language} />
            </div>
            <div className={scrollPosition > 1780 && scrollPosition < 7200 ? "floating-menu show" : "floating-menu hide"}>
                <div className="menu-container">
                    {
                        displayMenu &&
                        <div className="menu" onClick={() => {
                            scroll.scroller.scrollTo('activity', {
                                duration: 1500,
                                delay: 100,
                                smooth: true,
                                offset: -120})}}>
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/side-menu-prize.png" alt="Prize"/>
                            <span>{t('activity.title')}</span>
                        </div>
                    }
                    {    ((uuid && isChina && language === "sc") || (!uuid && language === "sc")) && (
                        <div className="menu"
                             onClick={() => {
                                 scroll.scroller.scrollTo('investment-panel', {
                                     duration: 1500,
                                     delay: 100,
                                     smooth: true,
                                     offset: -120
                                 })
                             }}>
                            <img
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/side-menu-mortarboard.png"
                                alt="Mortar Board"/>
                            <span>投资学堂</span>
                        </div>
                    )
                    }
                    {
                        displayMenu && menuJoin && (
                            <div className="menu" onClick={() => checkJoinRedirection()}>
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/side-menu-flag.png" alt="Flag"/>
                                <span>{menuJoinText}</span>
                            </div>
                        )
                    }
                    {((!uuid && language !== "kr") || (uuid && !isKorea && language !== "kr")) && !anniversaryEnd && (
                        <div className="menu"
                             onClick={() => {
                                 scroll.scroller.scrollTo('anniversary', {
                                     duration: 1500,
                                     delay: 100,
                                     smooth: true,
                                     offset: -120
                                 })
                             }}>
                            <img
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/img-7.png"
                                alt="Mortar Board"/>
                            <span>{t('investment.anniversary')}</span>
                        </div>
                    )}
                </div>
                {
                    displayMenu &&
                    <div className="scroll-top" onClick={() => scrollTop()}>
                        {t('banner.top')}
                    </div>
                }
            </div>
            <Investment
                language={language}
                uuid={uuid}
                isChina={isChina}
                isKorea={isKorea}
                anniversaryEnd={anniversaryEnd}/>
        </div>
    )
}

export default Content;
