import React from 'react';
import { useTranslation } from "react-i18next";

import './scss/about.scss';

const About = (props) => {
    const { close, language } = props;
    const { t } = useTranslation();

    const redirectPage = () => {
        switch (language) {
            case "sc":
            case "tc":
                window.location.href = "https://www.dooprimezhnews.com/dooprimedashibeishoujiequanqiujiaoyicishansaibanjiangdianlijicishanjuanzengyishiyuanmanchenggong/";
                break;
            default:
                window.location.href = "https://www.dooprimenews.com/industry/doo-prime-to-host-the-first-global-charity-competition-doo-prime-masters-cup/";
                break;
        }
    }

    return (
        <div className="modal-container about">
            <div className="modal">
                <button className="close" onClick={close}>
                    X
                </button>
                <header className="modal-header">
                    <div className="popup-wrapper">
                        <h5 className="title">{t('about.title')}</h5>
                        <p dangerouslySetInnerHTML={{__html: t('about.desc')}}></p>
                        <div className="button" onClick={() => redirectPage()}>{t('about.highlights')}</div>
                    </div>
                </header>
            </div>
        </div>
    )
}

export default About;
