import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Collapsible from 'react-collapsible';
import { WOW } from "wowjs";

const Footer = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();
    }, []);

    return (
        <section className="footer">
            <footer className="footer-content">
                <div className="middle-footer">
                    <div className="footer_menu wow fadeInUp">
                        <div className="container">
                            <a href='http://cdn.dootech.io/prime-website2/docs/Client_Agreement_Doo_Prime_Vanuatu_Limited_Aug_2021.pdf' target="_blank">{t('footer.customerIdentification')}</a>
                            <a href='http://cdn.dootech.io/prime-website2/docs/AMLAndCTFPolicy-Doo-Prime-Vanuatu-Limited-16- December-2020.pdf' target="_blank">{t('footer.amlAndCTFPolicy')}</a>
                            <a href='http://cdn.dootech.io/prime-website2/docs/Execution_Policy_(Doo Prime).pdf' target="_blank">{t('footer.executionPolicy')}</a>
                            <a href='http://cdn.dootech.io/prime-website2/docs/Refund_Policy_(Doo Prime).pdf' target="_blank">{t('footer.refundPolicy')}</a>
                            <a href='http://cdn.dootech.io/prime-website2/docs/updated/Risk%20Disclosure%20And%20Acknowledgement%20(Doo%20Prime%20Vanuatu%20Limited).pdf' target="_blank">{t('footer.riskAcknowledgement')}</a>
                            <a href='http://cdn.dootech.io/prime-website2/docs/updated/Privacy%20Policy%20(Doo%20Prime%20Vanuatu%20Limited).pdf' target="_blank">{t('footer.privacyPolicy')}</a>
                            <a href='http://cdn.dootech.io/prime-website2/docs/Website_Terms_and_Conditions_(Doo Prime).pdf' target="_blank" className="remove_border">{t('footer.websiteTAndC')}</a>
                        </div>
                    </div>
                    <div className="container" style={{padding: "0px 15px 37px"}}>
                        <div className="wow fadeInUp">
                            <div className="grid column7 hide_sm">
                                <div className="column remove_arrow">
                                    <div className="social wow fadeInUp">
                                        <a target="_blank" rel="noopener" className="brand"><img
                                        src="https://cdn.dootech.io/prime-website2/images/logo-DPfooter.svg" alt="Doo Prime Logo" /></a>
                                        <div className="hide_sm">
                                            <div className="pull_right">
                                                <a target="_blank" rel="noopener" href="https://www.facebook.com/DooPrime/"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-Facebook.svg" /></a>
                                                <a target="_blank" rel="noopener" href="https://www.instagram.com/dooprime/"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-INS.svg" /></a>
                                                <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/dooprime"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-Linkedin.svg" /></a>
                                                <a target="_blank" rel="noopener" href="https://twitter.com/dooprime"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-twitter.svg" /></a>
                                                <a className="qr_wechat"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-WeChat.svg" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.markets')}</h5>
                                    <div className="content">
                                        <p>{t('footer.forex')}</p>
                                        <p>{t('footer.preciousMetals')}</p>
                                        <p>{t('footer.energies')}</p>
                                        <p>{t('footer.spotIndex')}</p>
                                        <p>{t('footer.securities')}</p>
                                        <p>{t('footer.futures')}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.tools')}</h5>
                                    <div className="content">
                                        <span>{t('footer.tradingSoftware')}</span>
                                        <p>{t('footer.metaTrader4')}</p>
                                        <p>{t('footer.metaTrader5')}</p>
                                        <p>{t('footer.dooPrimeInTrade')}</p>
                                        <p>{t('footer.tradingView')}</p>
                                        <p className="mb14">{t('footer.fixApi')}</p>
                                        <span>{t('footer.socialTrading')}</span>
                                        <p>{t('footer.dooPrimeOutrade')}</p>
                                        <p className="mb14">{t('footer.myfxBook')}</p>
                                        <p>{t('footer.followme')}</p>
                                        <span>{t('footer.marketAnalysis')}</span>
                                        <p>{t('footer.tradingCentral')}</p>
                                        <p className="mb14">{t('footer.economicCalendar')}</p>
                                        <span>{t('footer.tools1')}</span>
                                        <p>{t('footer.vps')}</p>
                                        <p>{t('footer.realTimeHistory')}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.tradingfooter')}</h5>
                                    <div className="content">
                                        <p className="mb14">{t('footer.pricingModel')}</p>
                                        <p>{t('footer.technology')}</p>
                                        <p>{t('footer.accountComparision')}</p>
                                        <p>{t('footer.funding')}</p>
                                        <span>{t('footer.tradingConditions')}</span>
                                        <p>{t('footer.leverage')}</p>
                                        <p>{t('footer.overnightInterest')}</p>
                                        <p>{t('footer.tradingcalendar')}</p>
                                        <p>{t('footer.contractSpecification')}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.partners')}</h5>
                                    <div className="content">
                                        <span>{t('footer.membership')}</span>
                                        <p>{t('footer.membershipRewards')}</p>
                                        <span>{t('footer.partner')}</span>
                                        <p>{t('footer.introducingBroker')}</p>
                                        <p>{t('footer.whiteLabelPartner')}</p>
                                        <p>{t('footer.regionalOffices')}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.aboutus')}</h5>
                                    <div className="content">
                                        <p>{t('footer.ourStory')}</p>
                                        <p>{t('footer.corporateSocialResponsibility')}</p>
                                        <p>{t('footer.mediaCentre')}</p>
                                        <p>{t('footer.Compliance')}</p>
                                        <p>{t('footer.contaceUs')}</p>
                                        <p>{t('footer.career')}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.help')}</h5>
                                </div>
                            </div>
                            <div className="hide_mdlg">
                                <div className="column remove_arrow">
                                    <div className="social wow fadeInUp">
                                        <a target="_blank" rel="noopener" className="brand"><img
                                        src="https://cdn.dootech.io/prime-website2/images/logo-DPfooter.svg" alt="Doo Prime Logo" /></a>
                                        <div className="hide_sm">
                                            <div className="pull_right">
                                                <a target="_blank" rel="noopener" href="https://www.facebook.com/DooPrime/"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-Facebook.svg" /></a>
                                                <a target="_blank" rel="noopener" href="https://www.instagram.com/dooprime/"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-INS.svg" /></a>
                                                <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/dooprime"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-Linkedin.svg" /></a>
                                                <a target="_blank" rel="noopener" href="https://twitter.com/dooprime"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-twitter.svg" /></a>
                                                <a className="qr_wechat"><img
                                                    src="https://cdn.dootech.io/prime-website2/images/icon-share-WeChat.svg" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.markets')} >
                                        <div className="content">
                                            <p>{t('footer.forex')}</p>
                                            <p>{t('footer.preciousMetals')}</p>
                                            <p>{t('footer.energies')}</p>
                                            <p>{t('footer.spotIndex')}</p>
                                            <p>{t('footer.securities')}</p>
                                            <p>{t('footer.futures')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.tools')} >
                                        <div className="content">
                                            <span>{t('footer.tradingSoftware')}</span>
                                            <p>{t('footer.metaTrader4')}</p>
                                            <p>{t('footer.metaTrader5')}</p>
                                            <p>{t('footer.dooPrimeInTrade')}</p>
                                            <p>{t('footer.tradingView')}</p>
                                            <p className="mb14">{t('footer.fixApi')}</p>
                                            <span>{t('footer.socialTrading')}</span>
                                            <p>{t('footer.dooPrimeOutrade')}</p>
                                            <p className="mb14">{t('footer.myfxBook')}</p>
                                            <p>{t('footer.followme')}</p>
                                            <span>{t('footer.marketAnalysis')}</span>
                                            <p>{t('footer.tradingCentral')}</p>
                                            <p className="mb14">{t('footer.economicCalendar')}</p>
                                            <span>{t('footer.tools1')}</span>
                                            <p>{t('footer.vps')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.tradingfooter')} >
                                        <div className="content">
                                            <p className="mb14">{t('footer.pricingModel')}</p>
                                            <p>{t('footer.technology')}</p>
                                            <p>{t('footer.accountComparision')}</p>
                                            <p>{t('footer.funding')}</p>
                                            <span>{t('footer.tradingConditions')}</span>
                                            <p>{t('footer.leverage')}</p>
                                            <p>{t('footer.overnightInterest')}</p>
                                            <p>{t('footer.tradingcalendar')}</p>
                                            <p>{t('footer.contractSpecification')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.partners')} >
                                        <div className="content">
                                            <span>{t('footer.membership')}</span>
                                            <p>{t('footer.membershipRewards')}</p>
                                            <span>{t('footer.partner')}</span>
                                            <p>{t('footer.introducingBroker')}</p>
                                            <p>{t('footer.whiteLabelPartner')}</p>
                                            <p>{t('footer.regionalOffices')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.aboutus')} >
                                        <div className="content">
                                            <p>{t('footer.ourStory')}</p>
                                            <p>{t('footer.corporateSocialResponsibility')}</p>
                                            <p>{t('footer.mediaCentre')}</p>
                                            <p>{t('footer.Compliance')}</p>
                                            <p>{t('footer.contaceUs')}</p>
                                            <p>{t('footer.career')}</p>
                                            <span>{t('footer.mediaCentre')}</span>
                                            <p>{t('footer.home')}</p>
                                            <p>{t('footer.important')}</p>
                                            <p>{t('footer.companyNews')}</p>
                                            <p>{t('footer.event')}</p>
                                            <p>{t('footer.industryDynamic')}</p>
                                            <p>{t('footer.analaysis')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.help')} >
                                    </Collapsible>
                                </div>
                            </div>
                            <div className="column hide_mdlg remove_arrow alignCenter social_media">
                                <div className="pull_right">
                                    <a target="_blank" rel="noopener" href="https://www.facebook.com/DooPrime/"><img
                                        src="https://cdn.dootech.io/prime-website2/images/icon-share-Facebook.svg" /></a>
                                    <a target="_blank" rel="noopener" href="https://www.instagram.com/dooprime/"><img
                                        src="https://cdn.dootech.io/prime-website2/images/icon-share-INS.svg" /></a>
                                    <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/dooprime"><img
                                        src="https://cdn.dootech.io/prime-website2/images/icon-share-Linkedin.svg" /></a>
                                    <a target="_blank" rel="noopener" href="https://twitter.com/dooprime"><img
                                        src="https://cdn.dootech.io/prime-website2/images/icon-share-twitter.svg" /></a>
                                    <a className="qr_wechat"><img src="https://cdn.dootech.io/prime-website2/images/icon-share-WeChat.svg" /></a>
                                </div>
                            </div>
                        </div>
                        <h5 className="complianceTitle wow fadeInUp">{t('footer.ComplianceDisclosure')}</h5>
                        <p className="disclaimer-subtitle jurisdiction_eu" dangerouslySetInnerHTML={{__html: t('footer.DJurisdiction_eu')}} />
                        <p className="disclaimer-subtitle jurisdiction_aus">{t('footer.DJurisdiction_aus')}</p>
                        <p className="disclaimer-subtitle jurisdiction_uk">{t('footer.DJurisdiction_uk')}</p>
                        <p className="disclaimer-subtitle jurisdiction" dangerouslySetInnerHTML={{__html: t('footer.DJurisdiction')}} />
                        <div className="compliance_desc wow fadeInUp">
                            <ul className="complianceWrapper">
                                <li>
                                    <Collapsible trigger={t('footer.readCompliance_title')} >
                                        <div dangerouslySetInnerHTML={{__html: t('footer.readCompliance')}}></div>
                                    </Collapsible>
                                </li>
                                <li>
                                    <Collapsible trigger={t('footer.highRisk_title')} >
                                        <div dangerouslySetInnerHTML={{__html: t('footer.highRisk')}}></div>
                                    </Collapsible>
                                </li>
                                <li>
                                    <Collapsible trigger={t('footer.brandAndEntity_title')} >
                                        <div dangerouslySetInnerHTML={{__html: t('footer.brandAndEntity')}}></div>
                                    </Collapsible>
                                </li>
                                <li>
                                    <Collapsible trigger={t('footer.clientNotice_title')} >
                                        <div dangerouslySetInnerHTML={{__html: t('footer.clientNotice')}}></div>
                                    </Collapsible>
                                </li>
                            </ul>
                        </div>
                        <ul className="complianceWrapper wow fadeInUp">
                            <li className="topBorder">
                                <Collapsible trigger={t('footer.dooDropDown')} >
                                    <div className="bulletin wow fadeInUp">
                                        <div className="card">
                                            <h3>{t('footer.elishAndElishInc')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.elishAndElishIncDesc')}} />
                                            <a href="https://www.doofinancial.com" target="_blank">www.doofinancial.com</a>
                                            <br className="mobile" />
                                            <a href="https://www.elish.com" target="_blank">www.elish.com</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.dooClearingLimited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.dooClearingLimitedDesc')}} />
                                            <a href="https://www.dooclearing.co.uk" target="_blank">www.dooclearing.com</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.dooConsultingLimited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.dooConsultingLimitedDesc')}} />
                                            <a href="https://www.doogroup.com" target="_blank">www.doogroup.com</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.dootechnologyLimited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.dootechnologyLimitedDesc')}} />
                                            <a href="https://www.doo.tech" target="_blank">www.doo.tech</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.dooprimeVanautuLimited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.dooprimeVanautuLimitedDesc')}} />
                                            <a href="https://www.dooprimeglobal.com" target="_blank">www.dooprimeglobal.com</a>
                                            <a href="https://www.dooprime.com" target="_blank">www.dooprime.com</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.dooprimeMauritiusLimited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.dooprimeMauritiusLimitedDesc')}} />
                                            <a href="https://www.dooprime.mu" target="_blank">www.dooprime.mu</a>
                                        </div>
                                    </div>
                                </Collapsible>
                            </li>
                        </ul>
                    </div>
                    <div className="copyright wow fadeInUp">
                        <div className="container web">
                        {t('footer.cTCopyright')}
                        </div>
                    </div>
                </div>
            </footer>
        </section>
    )
}

export default Footer;
