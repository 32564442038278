import React, {useEffect, useState} from 'react';
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { WOW } from "wowjs";

import 'react-multi-carousel/lib/styles.css';

import './scss/news.scss';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const News = (props) => {
    const { t } = useTranslation();
    const { language } = props;
    const [postList, setPostList] = useState([]);

    useEffect(() => {
        const getBlogPosts = async() => {
            const posts = await axios({
                url: '/api/v1/blogtag/',
                method: 'GET',
                params: {
                    page: 0,
                    lang: language
                }
            });

            if(posts.data.code === 0) {
                setPostList(posts.data.data);
            }
        };

        getBlogPosts();
    }, [language]);

    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();
    }, []);

    return (
        <div id="news">
            <div className="section-title wow fadeInUp">
                {t('news.title')}
            </div>
            <Carousel
                responsive={responsive}
                showDots={true}
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={3000}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="news-carousel-container"
                dotListClass="news-custom-dot-list"
                itemClass="carousel-item-padding-40-px"
            >
                {
                    postList && postList.map(post => {
                        return (
                            <div className="post-container" onClick={() => window.location.href = post.guid}>
                                <img className="wow fadeInUp" src={post.attachmentPath}/>
                                <div id="title" className="wow fadeInUp">{post.title}</div>
                                <div id="excerpt" className="wow fadeInUp">{post.strippedStringlatestWpPost}</div>
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>


    )
}

export default News;
