import React, { useEffect, useState } from "react";
import QRCode from 'qrcode';
import { useTranslation } from 'react-i18next';

import './scss/invite-friend.scss';
import html2canvas from "html2canvas";
import logo from "../../assets/images/popup-logo.png";
import background_img from '../../assets/images/invite-friend.png';

const Invite = (props) => {
    const { language, uuid, close } = props;
    const [qr, setQr] = useState('');

    const { t } = useTranslation();

    const getImage = () => {
        let page = document.getElementById("invite-friend");
        let xPosition;
        let w = window.outerWidth;
        if(w > 787){
            xPosition = 8.4;
        } else {
            xPosition = 0;
        }

        window.scrollTo(0, 0);
        html2canvas(page, {
            scale: 3,
            useCORS: true,
            scrollY: -window.scrollY,
            height: page.scrollHeight
        }).then((canvas) => {
            canvas.toBlob(function(blob) {
                const URL = window.URL || window.webkitURL;
                const output = new Blob([blob], { type: 'image/jpeg' });
                const fileURL = URL.createObjectURL(output);

                if(navigator.userAgent.match('CriOS')) {
                    window.open(fileURL);
                } else {
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.target = '_blank';
                    link.download = 'winwithfriends.jpg';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            });
        });
    }

    useEffect(() => {
        const getQrCode = async() => {
            const url = await QRCode.toDataURL(window.location.origin.replace(/\/$/, '') + '?utm_source=Wechat&utm_medium=SocialMedia&utm_campaign=TradingContest&utm_term=InviteFriends&utm_content=' + uuid, {color: {dark: '#000000', light: '#FFFFFF'}, margin: 1, quality: 1});
            setQr(url);
        }

        getQrCode();
    }, []);

    return (
        <div className={`modal-container popupInvite ${language}`}>
            <div className="modal_wrapper">
                <div className="modal">
                    <button className="close" onClick={close}>
                        X
                    </button>
                    <div id="invite-friend">
                        <img id="background" src={background_img} alt="QR Background"/>
                        <div className="content-wrapper">
                            <header className="modal-header">
                                <img src={logo} alt="Logo"/>
                                <p className="border">{t('inviteFriend.charityCup')}</p>
                                <h5 className="title">{t('inviteFriend.globalTradingCom')}</h5>
                            </header>
                            <div className="modal-content">
                                <p className="border">{t('inviteFriend.unlockAmazingPrizes')}</p>
                                <div className="bonus" dangerouslySetInnerHTML={{__html: t('inviteFriend.cashPrizes')}}>
                                </div>
                                <div className="qrcode">
                                    <div className="wrapper">
                                        <img src={qr} alt="QR Code"/>
                                        <div>{t('inviteFriend.findOutMore')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" dangerouslySetInnerHTML={{__html: t('inviteFriend.highRiskDisclosure')}}>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="download-page">
                    <button onClick={getImage}>
                        {t('download')}
                    </button>
                </div>
            </div>
            
        </div>
    )
}

export default Invite;
