import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import Collapsible from 'react-collapsible';
import { WOW } from "wowjs";

import 'react-tabs/style/react-tabs.css';

import './scss/terms.scss';

const Terms = forwardRef((props, ref) => {
    const { language } = props;
    const { t } = useTranslation();
    const [openRankingTerms, setOpenRankingTerms] = useState(false);
    const [openPrizeTerms, setOpenPrizeTerms] = useState(false);
    const [openTab, setActiveTab] = useState(0);

    useImperativeHandle(ref,() => ({
        expandRankingTerms() {
            setOpenRankingTerms(true);
            setActiveTab(0);
        },
        expandPrizeTerms() {
            setOpenPrizeTerms(true);
            setActiveTab(1);
        }
    })
    );

    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();
    }, []);

    return (
        <div id="terms">
            <div className="section-title wow fadeInUp">
                {t('terms.title')}
            </div>
            <div className="wow fadeInUp">
                <Tabs 
                    selectedIndex={openTab}
                    onSelect={(k) => setActiveTab(k)}
                >
                    <TabList>
                        <Tab>{t('terms.tab-1.title')}</Tab>
                        <Tab>{t('terms.tab-2.title')}</Tab>
                        <Tab>{t('terms.tab-3.title')}</Tab>
                    </TabList>

                    <TabPanel>
                        <Collapsible trigger={t('terms.tab-1.date')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-1.dateDesc')}}>
                            </ol>
                        </Collapsible>
                        <Collapsible trigger={t('terms.tab-1.joinMethod')} >
                            <ol className="first between" dangerouslySetInnerHTML={{__html: t('terms.tab-1.joinMethodDesc')}}>
                            </ol>
                        </Collapsible>
                        <Collapsible trigger={t('terms.tab-1.joinSettings')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-1.joinSettingsDesc')}}>
                            </ol>
                        </Collapsible>
                        <div id="ranking-rules">
                            <Collapsible trigger={t('terms.tab-1.rankingRule')} open={openRankingTerms}>
                                <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-1.rankingRuleDesc')}}>
                                </ol>
                            </Collapsible>
                        </div>
                        <Collapsible trigger={t('terms.tab-1.clause')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-1.clauseDesc')}}>
                            </ol>
                        </Collapsible>
                    </TabPanel>
                    <TabPanel>
                        <div id="prize-rules">
                            <Collapsible trigger={t('terms.tab-2.prizeSettings')} open={openPrizeTerms}>
                                <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-2.prizeSettingsDesc')}}>
                                </ol>
                            </Collapsible>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <Collapsible trigger={t('terms.tab-3.aboutRegistration')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-3.aboutRegistrationDesc')}}>
                            </ol>
                        </Collapsible>
                        <Collapsible trigger={t('terms.tab-3.aboutOpenAccount')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-3.aboutOpenAccountDesc')}}>
                            </ol>
                        </Collapsible>
                        <Collapsible trigger={t('terms.tab-3.aboutDeposit')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-3.aboutDepositDesc')}}>
                            </ol>
                        </Collapsible>
                        <Collapsible trigger={t('terms.tab-3.aboutTransaction')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-3.aboutTransactionDesc')}}>
                            </ol>
                        </Collapsible>
                        <Collapsible trigger={t('terms.tab-3.aboutRanking')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-3.aboutRankingDesc')}}>
                            </ol>
                        </Collapsible>
                        <Collapsible trigger={t('terms.tab-3.aboutRewards')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-3.aboutRewardsDesc')}}>
                            </ol>
                        </Collapsible>
                        {
                            language === "sc" &&
                                <>
                                    <Collapsible trigger={t('terms.tab-3.aboutRedeem')} >
                                        <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-3.aboutRedeemDesc')}}>
                                        </ol>
                                    </Collapsible>
                                    <Collapsible trigger={t('terms.tab-3.aboutPointsMall')} >
                                        <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-3.aboutPointsMallDesc')}}>
                                        </ol>
                                    </Collapsible>
                                </>
                        }
                        <Collapsible trigger={t('terms.tab-3.aboutInvite')} >
                            <ol className="first" dangerouslySetInnerHTML={{__html: t('terms.tab-3.aboutInviteDesc')}}>
                            </ol>
                        </Collapsible>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
})

export default Terms;
