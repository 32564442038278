import React from 'react';
import Bars from 'react-bars';

import './index.scss';

const ProgressBar = (props) => {
    const { data } = props;

    return (
        <>
            <Bars data={data}/>
        </>
    );
}

export default ProgressBar;
