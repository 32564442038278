import React from 'react';
import FlipCountdown from './function';
import { useTranslation } from "react-i18next";

const Countdown = (props) => {
    const { t } = useTranslation();
    const { date } = props;

    return (
        <>
            <FlipCountdown
                dayTitle={t('banner.countdown.day')}
                hourTitle=':'
                minuteTitle=':'
                endAtZero
                hideYear
                hideMonth
                theme="light"
                endAt={date}
            />
            <div className="disclaimer">*UTC+0</div>
        </>
    )
}

export default Countdown;
