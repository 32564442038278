import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import Collapsible from 'react-collapsible';
import { WOW } from "wowjs";

import Dropdown from '../common/dropdown';

const languages = [
    { value: 'en', label: 'English' },
    { value: 'sc', label: '简体中文' },
    { value: 'tc', label: '繁體中文' },
    { value: 'kr', label: '한국어' },
    { value: 'vn', label: 'Tiếng Anh' },
    { value: 'th', label: 'ภาษาไทย' }
];

const customDropdown = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' : 'grey',
        padding: 20,
    }),
    control: () => ({
        width: 150,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
};

const Navbar = (props) => {
    const { t } = useTranslation();
    const { changeLanguage, config, defaultLanguage, triggerPopup, navbarOpen, uuid, isParticipant } = props;
    const [scrollPosition, setScrollPosition] = useState(null);
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', updateScroll);
    },[scrollPosition]);

    useEffect(() => {
        navbarOpen(hamburgerOpen);
    },[hamburgerOpen]);

    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();
    }, []);

    const getSelectedLanguage = languages.find(obj => obj.value === defaultLanguage);

    const changeSelected = (selected) => {
        changeLanguage(selected.value);
        window.location.href = window.location.origin + "/" + selected.value;
    };

    const openPopup = (type) => {
        triggerPopup(type);
    };

    const updateScroll = () => {
        setScrollPosition(window.scrollY);
    };

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    };

    const selectLanguage = (selected) => {
        changeSelected(selected);
        toggleHamburger();
    };

    const redirectLogin = () => {
        window.location.href = config.crmUrl;
    };

    const logout = () => {
        localStorage.clear();
        window.location.reload(false);
    };

    return (
        <>
            <div className="navigation desktop">
                <div className={scrollPosition > 75 ? "navigation-container colour-change" : "navigation-container"}>
                    <div className="logo wow fadeInUp">
                        <a href="https://www.dooprimeio.com/" target="_blank">
                            <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/Logo-dooprime.svg" alt="Doo Prime Logo"/>
                        </a>
                    </div>
                    <div className="menu wow fadeInUp">
                        <ul>
                            <li onClick={() => openPopup('about')}>{t('navigation.about')}</li>
                            {
                                !isParticipant &&
                                    <li><Link to="guide" spy={true} smooth={true} offset={-120}>{t('navigation.guide')}</Link></li>
                            }

                            <li><Link to="ranking" spy={true} smooth={true} offset={-120}>{t('navigation.ranking')}</Link></li>
                            <li><Link activeClass="active" to="prize" spy={true} smooth={true} offset={-120}>{t('navigation.prize')}</Link></li>
                            <li><Link to="terms" spy={true} smooth={true} offset={-120} dangerouslySetInnerHTML={{__html: t('navigation.terms')}}></Link></li>
                            <li><Link to="activity" spy={true} smooth={true} offset={-120} dangerouslySetInnerHTML={{__html: t('navigation.activity')}}></Link></li>
                            <li><Link to="news" spy={true} smooth={true} offset={-120}>{t('navigation.news')}</Link></li>
                        </ul>
                    </div>
                    <div className="side wow fadeInUp">
                        {
                            !!uuid &&
                                <>
                                    <div className="greeting">Hello, {uuid}</div>
                                    <div className="greeting logout" onClick={() => logout()}>{t('navigation.logout')}</div>
                                </>
                        }
                        {
                            !uuid &&
                                <div className="login-button" onClick={() => redirectLogin()}>{t('navigation.login')}</div>
                        }
                        <Dropdown className="language-dropdown wow fadeInUp" options={languages} styles={customDropdown} placeholder="" changeSelected={changeSelected} value={getSelectedLanguage}/>
                    </div>
                </div>
            </div>
            <div className="navigation mobile">
                <div className={hamburgerOpen ? "hamburger hamburger-open" : "hamburger hamburger-close"}>
                    <div className="hamburger-top wow fadeInUp">
                        <a href="https://www.dooprimeio.com/" target="_blank">
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/dooprime-logo-mobile.svg" alt="Doo Prime Logo"/>
                        </a>
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon-common-close.png" alt="Close" onClick={toggleHamburger}/>
                    </div>
                    <div className="hamburger-menu wow fadeInUp">
                        <ul>
                            <li onClick={() => openPopup('about')}>{t('navigation.about')}</li>
                            <li><Link to="guide" spy={true} smooth={true} offset={-120} onClick={toggleHamburger}>{t('navigation.guide')}</Link></li>
                            <li><Link to="ranking" spy={true} smooth={true} offset={-100} onClick={toggleHamburger}>{t('navigation.ranking')}</Link></li>
                            <li><Link activeClass="active" to="prize" spy={true} smooth={true} offset={-100} onClick={toggleHamburger}>{t('navigation.prize')}</Link></li>
                            <li><Link to="terms" spy={true} smooth={true} offset={-100} onClick={toggleHamburger}>{t('navigation.terms')}</Link></li>
                            <li><Link to="activity" spy={true} smooth={true} offset={-100} onClick={toggleHamburger}>{t('navigation.activity')}</Link></li>
                            <li><Link to="news" spy={true} smooth={true} offset={-100} onClick={toggleHamburger}>{t('navigation.news')}</Link></li>
                            <li>
                                <Collapsible trigger={getSelectedLanguage.label} >
                                    {
                                        languages.map(language => {
                                            return <ol className={language.label === getSelectedLanguage.label ? "selected": ""} onClick={() => selectLanguage({ value: language.value, label: language.label })}>
                                                {language.label}
                                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon-common-ok.png" alt="Check"/>
                                            </ol>
                                        })
                                    }
                                </Collapsible>
                            </li>
                        </ul>
                        <div className="hamburger-buttons">
                            {
                                !uuid &&
                                    <>
                                        <div className="login-button" onClick={() => redirectLogin()}>{t('navigation.login')}</div>
                                        <div className="signup-button" onClick={() => openPopup("crm-register")}>{t('navigation.signup')}</div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
                <div className={scrollPosition > 80 ? "navigation-container colour-change" : "navigation-container"}>
                    <div className="container-left">
                        <div className="hamburger wow fadeInUp" onClick={toggleHamburger}>
                            <div className="burger"/>
                            <div className="burger"/>
                            <div className="burger"/>
                        </div>
                        <div className="logo wow fadeInUp">
                            <a href="https://www.dooprimeio.com/" target="_blank">
                                <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/Logo-dooprime.svg" alt="Doo Prime Logo"/>
                            </a>
                        </div>
                    </div>
                    <div className="container-right">
                        <div className="login wow fadeInUp">
                            {
                                !!uuid &&
                                    <>
                                        <div className="greeting">Hello, {uuid}</div>
                                        <div className="greeting logout" onClick={() => logout()}>{t('navigation.logout')}</div>
                                    </>
                            }
                            {
                                !uuid &&
                                    <div className="open-account" onClick={() => redirectLogin()}>{t('navigation.login')}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar;
