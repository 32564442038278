import React from 'react';

import './scss/redeem-fail.scss';

const RedeemFail = (props) => {
    const { close, content } = props;

    return (
        <div className="modal-container redeem-fail">
            <div className="modal">
                <button className="close" onClick={close}>
                    X
                </button>
                <header className="modal-header">
                    <div className="popup-wrapper" dangerouslySetInnerHTML={{__html: content}}>
                    </div>
                </header>
            </div>
        </div>
    );
}

export default RedeemFail;
