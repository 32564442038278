import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './scss/index.scss';

import About from './about';
import Login from './login';
import Info from './info';
import RedeemForm from './redeem-form';
import RedeemSuccess from './redeem-success';
import RedeemFail from './redeem-fail';
import Error from './error';
import CrmRegister from './crm-register';
import InviteFriend from './invite-friend';
import UserResults from './user-results';

const PopUp = (props) => {
    const { close, type, uuid, content, language, config, triggerPopup, getEligibility, countdownText, countdownTimer } = props;
    const [provinceList, setProvinceList] = useState([]);
    const [completeCityList, setCompleteCityList] = useState({});

    useEffect(() => {
        if (type === 'redeem-form') {
            getCityList();
            getProvinceData();
        }
    }, []);

    const getProvinceData = async() => {
        const province = await axios({
            url: '/api/v1/activity/region/province/',
            method: 'GET'
        });

        if(province.data.data.code === 0) {
            const data = province.data.data.data.map(item => ['province_id', 'province_name_ch'].reduce((obj, key) => {
                obj[key] = item[key];
                return obj;
            }, {}));

            setProvinceList(data);
        }
    };

    const getCityList = async() => {
        const cities = await axios({
            url: '/api/v1/activity/region/city/',
            method: 'GET'
        });

        if(cities.data.data.code === 0) {
            const data = cities.data.data.data.map(item => ['city_id', 'province_id', 'city_name_ch'].reduce((obj, key) => {
                obj[key] = item[key];
                return obj;
            }, {}));

            const list = data.reduce((h, obj) => Object.assign(h, { [obj.province_id]:( h[obj.province_id] || [] ).concat(obj) }), {});

            setCompleteCityList(list);
        }
    };

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    const eligibility = () => {
        getEligibility();
    }

    return (
        <div>
            {
                type === 'about' &&
                    <About close={close} language={language}/>
            }
            {
                type === 'info' &&
                    <Info close={close} content={content}/>
            }
            {
                type === 'error' &&
                    <Error close={close}/>
            }
            {
                type === 'login' &&
                    <Login close={close}/>
            }
            {
                type === 'crm-register' &&
                    <CrmRegister close={close} config={config} language={language} countdownText={countdownText} countdownTimer={countdownTimer}/>
            }
            {
                type === 'redeem-form' &&
                    <RedeemForm close={close} config={config} provinceList={provinceList} completeCityList={completeCityList} triggerPopup={openPopup.bind(this)} getEligibility={eligibility.bind(this)}/>
            }
            {
                type === 'redeem-success' &&
                    <RedeemSuccess close={close} content={content}/>
            }
            {
                type === 'redeem-fail' &&
                    <RedeemFail close={close} content={content}/>
            }
            {
                type === 'invite-friend' &&
                    <InviteFriend close={close} language={language} uuid={uuid}/>
            }
            {
                type === 'user-results' &&
                    <UserResults close={close} language={language} config={config} uuid={uuid} results={content}/>
            }
        </div>
    )
}

export default PopUp;
