import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import GroupedDropdown from "../common/dropdown/grouped";
import axios from 'axios';

import 'react-phone-input-2/lib/style.css';

const customDropdown = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' : 'grey',
        padding: 20,
    }),
    control: () => ({
        width: 150
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
};

const RedeemForm = (props) => {
    const { close, config, provinceList, completeCityList, triggerPopup, getEligibility } = props;
    const [name, setName] = useState(null);
    const [contact, setContact] = useState(null);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [address, setAddress] = useState(null);
    const [filteredCityList, setFilteredCityList] = useState([]);
    const [placeholder, setPlaceholder] = useState('所在地区');

    const getProvinceList = provinceList.map(elm => ({value: elm.province_id, label: elm.province_name_ch}));

    useEffect(() => {
        filterCityList();
    }, [selectedProvince]);

    useEffect(() => {
        getPlaceholder();
    }, [selectedProvince, selectedCity]);

    const filterCityList = () => {
        const data = completeCityList[selectedProvince];

        if (data) {
            const list = data.map(elm => ({value: elm.city_id, label: elm.city_name_ch}));
            setFilteredCityList(list);
        }
    };

    const groupedOptions = [
        {
            label: '省',
            options: getProvinceList,
        },
        {
            label: '市',
            options: filteredCityList,
        },
    ];

    const changeSelected = (group, selected) => {
        switch(group) {
            case 0:
                setSelectedProvince(selected.value);

                // reset city selection on every province change
                setSelectedCity(null);
                break;
            case 1:
                setSelectedCity(selected.label);
                break;
        }
    };

    const getPlaceholder = () => {
        const province = getProvinceList.find(data => data.value === selectedProvince);
        setPlaceholder(province ? (province.label + " / " + (selectedCity ? selectedCity : '')) : '所在地区');
    };

    const updateEligibility = () => {
        getEligibility();
    };

    const checkContactValidity = (contact) => {
        return /^1[0-9]{10}$/.test(contact);
    };



    const handleRedeem = async(e) => {
        e.preventDefault();

        if (name && checkContactValidity(contact) && selectedProvince && selectedCity && address) {
            const province = getProvinceList.find(data => data.value === selectedProvince);

            const submitForm = await axios({
                url: '/api/v1/activity/gifts/create',
                method: 'POST',
                withCredentials: true,
                headers: {
                    "Access-Control-Allow-Origin": config.apiUrl
                },
                data: {
                    receiveName: name,
                    receivePhoneCode: '+86',
                    receiveContact: contact,
                    receiveAddress: address + " " + province.label + " " + selectedCity
                }
            });

            if (submitForm.data.code === 0) {
                updateEligibility();
                openPopup('redeem-success', submitForm.data.data);
            } else {
                let error = '';

                switch(submitForm.data.code) {
                    case 3002:
                    case 3003:
                        error = '抱歉，请先满足换领条件后再尝试。';
                        break;
                    case 3004:
                        error = '抱歉，今天的限量礼品已换领完毕，明天再來吧。';
                        break;
                }
                openPopup('redeem-fail', error);
            }
        }
    };

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    const handleNameInput = (e) => {
        setName(e.target.value);
    };

    const handleAddressInput = (e) => {
        setAddress(e.target.value);
    };

    return (
        <div className="modal-container redeem-form">
            <div className="modal">
                <button className="close" onClick={close}>
                    X
                </button>
                <header className="modal-header">
                    <div className="popup-wrapper">
                        <h5 className="title">领取限时礼品</h5>
                        <p className="content">
                            感谢您对#DooTrader的支持！<br/>
                            请正确填写寄送信息，我们会有专人安排寄送礼品。
                        </p>
                    </div>
                </header>
                {
                    getProvinceList.length > 0 &&
                        <form onSubmit={handleRedeem}>
                            <div className="name-text">
                                {
                                    (name === null || name === '') &&
                                        <p className="error">请输入收件人名称</p>
                                }
                                <input type="text"
                                       name="name"
                                       placeholder="收件人名称"
                                       onChange={handleNameInput.bind(this)}/>
                            </div>
                            <PhoneInput
                                inputProps={{
                                    name: 'phone',
                                    autoFocus: true
                                }}
                                isValid={(value, country) => {
                                    if (checkContactValidity(value)) {
                                        return true;
                                    } else {
                                        return '请输入有效的电话号码';
                                    }
                                }}
                                disableCountryCode={true}
                                onlyCountries={['cn']}
                                country={'cn'}
                                value={contact}
                                onChange={phone => setContact(phone)}
                            />
                            {
                                getProvinceList && Object.keys(completeCityList).length > 0 &&
                                    <div className="address-select">
                                        {
                                            (selectedProvince === null || selectedCity === null) &&
                                                <p className="error">请选择省份及城市</p>
                                        }
                                        <GroupedDropdown className="province-dropdown"
                                                         options={groupedOptions}
                                                         styles={customDropdown}
                                                         placeholder={placeholder}
                                                         changeSelected={changeSelected}
                                                         value={''}/>
                                    </div>
                            }
                            {
                                selectedProvince && selectedCity &&
                                    <div className="address-text">
                                        {
                                            address === '' &&
                                                <p className="error">请输入详细地址</p>
                                        }
                                        <textarea name="address"
                                                  placeholder="详细地址"
                                                  onBlur={handleAddressInput.bind(this)}/>
                                    </div>
                            }
                            <button type="submit">
                                领取礼品
                            </button>
                        </form>
                }
            </div>
        </div>
    )
}

export default RedeemForm;
