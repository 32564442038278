import React, { useState } from 'react';
import Select, { components } from 'react-select';

import './index.scss';

const GroupedDropdown = (props) => {
    const { className, options, styles, placeholder, changeSelected, value } = props;
    const [multi, setMulti] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(0);

    const selectGroup = (e, groupIndex) => {
        setSelectedGroup(groupIndex);
    };

    const handleChange = (selected) => {
        changeSelected(selectedGroup, selected);

        if (selectedGroup === 1) {
            closeMenu();
        }
    };

    const openMenu = () => {
        setMulti(true);
    };

    const closeMenu = () => {
        setMulti(false);
    };

    const getTabStyle = (isActive) => ({
        width: "50%",
        textAlign: "center",
        padding: "3px 5px",
        cursor: "pointer",
        borderBottom: isActive && "2px solid #e72e2e"
    });

    const Menu = ({ children, ...props }) => {
        return (
            <components.Menu {...props}>
                <div style={{
                    display: "flex",
                    height: '35px',
                    lineHeight: '35px',
                    padding: "10px auto"
                }}>
                    {props.selectProps.options.map((option, index) => (
                        <div onClick={e => selectGroup(e, index)}
                             style={getTabStyle(selectedGroup === index)}>
                            {props.selectProps.getOptionLabel(option)}
                        </div>
                    ))}
                </div>
                {children}
            </components.Menu>
        );
    };

    const MenuList = ({ children, ...props }) => {
        const filteredChildren = React.Children.toArray(children).filter(
            (group, index) => {
                if (index === selectedGroup) return true;
                return false;
            }
        );

        return (
            <components.MenuList {...props}>{filteredChildren}</components.MenuList>
        );
    };

    return (<Select
        className={className}
        classNamePrefix="react-select"
        options={options}
        styles={styles}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        onFocus={openMenu}
        onBlur={closeMenu}
        menuIsOpen={multi}
        components={{
            Menu,
            MenuList
        }}
        required
    />);
}

export default GroupedDropdown;
