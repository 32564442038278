import React from 'react';

const Login = (props) => {
    const { close } = props;

    const handleLogin = (e) => {
        e.preventDefault();
        console.log("username:", e.target.username.value);
        console.log("password:", e.target.password.value);
    }

    return (
        <div className="modal-container">
            <div className="modal">
                <header className="modal-header">
                    <h2 className="modal-header-title">Sign In</h2>
                    <button className="close" onClick={close}>
                        X
                    </button>
                </header>
                <form className="login-form" onSubmit={handleLogin}>
                    <main className="modal-content">
                        <div>
                            <label>Username</label>
                            <input type="text" name="username"/>
                        </div>
                        <div>
                            <label>Password</label>
                            <input type="password" name="password"/>
                        </div>
                    </main>
                    <footer className="modal-footer">
                        <button className="modal-submit" type="submit">Submit</button>
                        <button className="modal-close" onClick={close}>
                            Cancel
                        </button>
                    </footer>
                </form>
            </div>
        </div>
    )
}

export default Login;
