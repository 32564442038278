import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import { WOW } from "wowjs";

import './scss/investment.scss';

const responsive = {
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Investment = (props) => {
    const { language, uuid, isChina, isKorea, anniversaryEnd } = props;
    const { t } = useTranslation();

    useEffect(() => {
        const wow = new WOW(
            {
              live: false,
            }
          );
          wow.init();

        // if(!isKorea) {
        //     let anniversary = document.getElementsByClassName('anniversary');
        //     console.log("anniversary", anniversary);
        //     for(let i = 0; i < anniversary.length; i++) {
        //         anniversary[i].classList.add('show');
        //     }
        // }
    }, []);

    return (
        <div id="investment" className={language}>
            <div className="container">
                <div className="title wow fadeInUp" dangerouslySetInnerHTML={{__html: t('why.title')}}></div>
                <div className="desc wow fadeInUp" data-wow-delay="0.5s">
                    {t('why.desc')}
                </div>
                <div className="allContent desktop" >
                <div className="content wow fadeInUp" data-wow-delay="0.5s">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_1.png"/>
                        <h5>{t('why.point-1.title')}</h5>
                        <p>{t('why.point-1.desc')}</p>
                    </div>
                    <div className="content wow fadeInUp" data-wow-delay="0.5s">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_2.png"/>
                        <h5>{t('why.point-2.title')}</h5>
                        <p>{t('why.point-2.desc')}</p>
                    </div>
                    <div className="content wow fadeInUp" data-wow-delay="0.5s">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_3.png"/>
                        <h5>{t('why.point-3.title')}</h5>
                        <p>{t('why.point-3.desc')}</p>
                    </div>
                    <div className="content wow fadeInUp" data-wow-delay="0.5s">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_4.png"/>
                        <h5>{t('why.point-4.title')}</h5>
                        <p>{t('why.point-4.desc')}</p>
                    </div>
                    <div className="content wow fadeInUp" data-wow-delay="0.5s">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_5.png"/>
                        <h5>{t('why.point-5.title')}</h5>
                        <p>{t('why.point-5.desc')}</p>
                    </div>
                    <div className="content wow fadeInUp" data-wow-delay="0.5s">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_6.png"/>
                        <h5>{t('why.point-6.title')}</h5>
                        <p>{t('why.point-6.desc')}</p>
                    </div>
                </div>
                <Carousel
                    responsive={responsive}
                    showDots={true}
                    arrows={false}
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="investment-carousel-container mobile"
                    dotListClass="investment-custom-dot-list"
                    itemClass="carousel-item-padding-40-px"
                >
                    <div className="content-pack">
                        <div className="content wow fadeInUp">
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_1.png"/>
                            <h5>{t('why.point-1.title')}</h5>
                            <p>{t('why.point-1.desc')}</p>
                        </div>
                        <div className="content wow fadeInUp">
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_2.png"/>
                            <h5>{t('why.point-2.title')}</h5>
                            <p>{t('why.point-2.desc')}</p>
                        </div>
                    </div>
                    <div className="content-pack">
                        <div className="content wow fadeInUp">
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_3.png"/>
                            <h5>{t('why.point-3.title')}</h5>
                            <p>{t('why.point-3.desc')}</p>
                        </div>
                        <div className="content wow fadeInUp">
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_4.png"/>
                            <h5>{t('why.point-4.title')}</h5>
                            <p>{t('why.point-4.desc')}</p>
                        </div>
                    </div>
                    <div className="content-pack">
                        <div className="content wow fadeInUp">
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_5.png"/>
                            <h5>{t('why.point-5.title')}</h5>
                            <p>{t('why.point-5.desc')}</p>
                        </div>
                        <div className="content wow fadeInUp">
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/icon_6.png"/>
                            <h5>{t('why.point-6.title')}</h5>
                            <p>{t('why.point-6.desc')}</p>
                        </div>
                    </div>
                </Carousel>
            </div>
            {
                ((uuid && isChina && language === "sc") || (!uuid && language === "sc")) &&
                    <div className="container mw1458" id="investment-panel">
                        <div className="bottom-panel">
                            <h3 className="wow fadeInUp">投资学堂</h3>
                           <div className="wow fadeInUp">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/career.jpg"/>
                                <h4>外汇交易市场上碰壁，是心态，还是知识不够
                                    如何建立有效的分析系统，让交易更上一楼？</h4>
                                <p>#DooTrader 秉承 “提高行业素质，传播正确交易理念” 的宗旨，本次大赛非常荣幸可以与专业认证的培训机构合作，提供不同阶段的培训课程，让参赛者可以与一众外汇专家，全面剖析外汇市场，极速掌握操盘技巧。<span>课程合格者更可获机构颁发“外汇专业分析师”证书。</span></p>
                                <p><b>名额有限，报名从速，立即点击右下角的在线服务了解更多</b>。</p>
                            </div>
                        </div>
                    </div>
            }
            { 
                ((!uuid && language !== "kr") || (uuid && !isKorea && language !== "kr")) && !anniversaryEnd &&
                    <div className="anniversary">
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/banner_anniversary.jpg"/>
                        <div className="content">
                            <div className="container ">
                                <h5>{t('investment.anniversary_title')}</h5>
                                <p>{t('investment.anniversary_con')}</p>
                                <a href={'https://www.dooprimeoffer.com/7years/' + language} target="_blank">{t('investment.anniversary_join')}</a>
                            </div>
                        </div>
                    </div>
            }
            
        </div>
    )
}

export default Investment;
